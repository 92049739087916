import React from 'react';
import { Text, Button, Card, Center, Stack, Container } from '@chakra-ui/react';
import Marquee from 'react-fast-marquee';
import img from '../../image/timelinebg.webp';
import { useInView, animated } from '@react-spring/web';

export default function Timelinebox() {
  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        x: -100,
      },
      to: {
        opacity: 1,
        x: 0,
      },
    }),
    { once: true }
  );

  return (
    <animated.div ref={ref} style={springs}>
      <Container maxW={'82rem'}>
        <Center>
          <Card
            loading="lazy"
            backgroundImage={img}
            backgroundSize={'cover'}
            w={'100%'}
            h={{
              base: '320px',
              sm: '320px',
              md: '320px',
              lg: '330px',
              xl: '400px',
              '2xl': '400px',
            }}
            borderRadius={'20px'}
            justify={'space-between'}
          >
            <div className="sliding-text">
              <Marquee gradient={false} direction={'right'} speed={20}>
                <ul>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                </ul>
              </Marquee>
            </div>
            <Stack justify={'center'} align={'center'}>
              <Text
                fontWeight="bold"
                color="white"
                lineHeight={1.2}
                fontSize={{
                  base: '40px',
                  sm: '40px',
                  md: '40px',
                  lg: '50px',
                  xl: '60px',
                  '2xl': '60px',
                }}
              >
                Our Roadmap
                <br />
              </Text>
              <Button
                as={'a'}
                href="/roadmap"
                className="buttonShadow"
                display="flex"
                flexDirection="row"
                rounded="20px"
                size="lg"
                fontWeight="normal"
                px={5}
                m={3}
                border={'solid 2px white'}
                colorScheme="black"
                bg={'transparent'}
              >
                <span> Discover Our Roadmap</span>
              </Button>
            </Stack>
            <div className="sliding-text">
              <Marquee gradient={false} speed={20}>
                <ul>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                  <li>ROADMAP</li>
                </ul>
              </Marquee>
            </div>
          </Card>
        </Center>
      </Container>
    </animated.div>
  );
}
