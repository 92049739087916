import { React, useRef, useEffect } from 'react';
import Typed from 'typed.js';
import {
  Box,
  Text,
  Card,
  Center,
  Flex,
  Container,
  Button,
} from '@chakra-ui/react';
import './Typed.css';
import img from '../../image/hero.webp';
import { useInView, animated } from '@react-spring/web';

const Hero = () => {
  const [see, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        x: -100,
      },
      to: {
        opacity: 1,
        x: 0,
      },
    }),
    { once: true }
  );
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ['While having fun with our awesome games!'],
      typeSpeed: 80,
      backSpeed: 30,
      startDelay: 1000,
      backDelay: 2000,
      loop: true,
      loopCount: Infinity,
      showCursor: true,
      cursorChar: '|',
    });

    // Destroying
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <animated.div ref={see} style={springs}>
      <Container maxW={'82rem'} mt={'30px'}>
        <Center>
          <Card
            backgroundImage={img}
            backgroundSize={'cover'}
            w={'100%'}
            h={{
              base: '320px',
              sm: '320px',
              md: '325px',
              lg: '380px',
              xl: '500px',
              '2xl': '500px',
            }}
            mt={'1%'}
            borderRadius={'20px'}
            pt={{
              base: '20px',
              sm: '25px',
              md: '30px',
              lg: '35px',
              xl: '40px',
              '2xl': '50px',
            }}
            pb={{
              base: '6px',
              sm: '10px',
              md: '12px',
              lg: '13px',
              xl: '18px',
              '2xl': '20px',
            }}
            pl={{
              base: '20px',
              sm: '30px',
              md: '40px',
              lg: '50px',
              xl: '56px',
              '2xl': '64px',
            }}
            justify={'space-between'}
            alignItems={'flex-start'}
          >
            <Box
              mx={15}
              my={15}
              textAlign="start"
              display="flex"
              flexDir="column"
              justifyContent="space-around"
              maxW={{
                base: '380px',
                sm: '320px',
                md: '500px',
                lg: '650px',
                xl: '750px',
                '2xl': '750px',
              }}
            >
              <Text
                color="white"
                fontFamily={'Urbanist'}
                fontWeight={'600'}
                lineHeight={1.2}
                fontSize={{
                  base: '30px',
                  sm: '40px',
                  md: '40px',
                  lg: '50px',
                  xl: '70px',
                  '2xl': '70px',
                }}
              >
                Earn UnipolyCoin
                <br />
                <span ref={el} className="gradientText"></span>
              </Text>
            </Box>
            <Flex
              w={{
                base: '90%',
                sm: '90%',
                md: '300px',
                lg: '300px',
                xl: '300px',
                '2xl': '300px',
              }}
              flexDir="row"
              justify={{ base: 'center', sm: 'center', md: 'flex-start' }}
            >
              <Button
                as={'a'}
                mt={'-70px'}
                bg="transparent"
                borderRadius={'20px'}
                border={'solid 1px white'}
                color={'white'}
                w={{
                  base: '90%',
                  sm: '90%',
                  md: '300px',
                  lg: '300px',
                  xl: '300px',
                  '2xl': '200px',
                }}
                href="https://unipolycoin.com/WhitepaperEn.pdf"
                flexDir="row"
                justify={{ base: 'center', sm: 'center', md: 'flex-start' }}
                _hover={{
                  backgroundColor: '#fffd8d55',
                  color: 'white',
                  border: 'solid 1px #fffa2b',
                  transition: 'color 0.3s ease',
                }}
              >
                Whitepaper
              </Button>
            </Flex>
          </Card>
        </Center>
      </Container>
    </animated.div>
  );
};

export default Hero;
