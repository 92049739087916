'use client';

import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';

import { Divider, Box } from '@chakra-ui/react';

import '../legal/legal.css';

export default function Items() {
  return (
    <Box background={'#010810'}>
      <Navbar />
      <br />
      <section className="legal-section">
        <div className="legal-wrapper">
          <div className="legal-header">
            <p>ÇEREZ POLİTİKASI VE ÇEREZ AYDINLATMA METNİ</p>
            <hr />
          </div>
          <div className="text-wrapper">
            <p>
              Esentepe Mah. Haberler Sk. No: 1 İç Kapı No: 1 Şişli / İstanbul
              adresinde faaliyet gösteren Unıpoly Yazılım ve Teknoloji Anonim
              Şirketi <b>(Unipoly veya Şirket)</b> tarafından işletilen ve
              yönetilen
              <a href="https://unipolycoin.com/" style={{ color: '#fffa2b' }}>
                https://unipolycoin.com/
              </a>
              internet sitesinin <b>(Site)</b> kullanımı kapsamında herhangi bir
              şekilde Site’yi ziyaretleri sırasında Unipoly tarafından çerezler
              <b>(Çerez/Çerezler)</b> kullanılmaktadır. Bu Çerez kullanımı başta
              6698 sayılı Kişisel Verilerin Korunması Kanunu <b>(KVKK)</b> olmak
              üzere mevzuata uygun şekilde gerçekleştirilmektedir. Unipoly,
              ilgili kişilere ait kişisel verileri KVKK hükümlerine aykırı
              olarak başkasına açıklayamaz ve işleme amacı dışında kullanamaz.
            </p>
            <h2>1. Çerez (Cookie) Nedir?</h2>
            <p>
              Çerezler, ziyaret ettiğiniz internet siteleri tarafından
              tarayıcılar aracılığıyla bilgisayarınıza (ya da akıllı telefon
              veya tablet gibi diğer cihazlarınıza) kaydedilen ve genelde harf
              ve rakamlardan oluşan çok küçük metin dosyalarıdır.
            </p>
            <p>
              Çerezler, ziyaret ettiğiniz web sitesini yöneten sunucular
              tarafından oluşturulurlar. Böylelikle ziyaretçi aynı siteyi
              ziyaret ettiğinde sunucu bunu anlayabilir. Çerezler, web sitesi
              sahiplerine aynı ziyaretçinin siteyi yeniden ziyaret ettiğini
              gösteren kimlik kartlarına benzetilebilir.
            </p>
            <h2>2. Çerez Türleri, Amaçları ve Hukuki Sebepler</h2>
            <p>
              Genel bir açıklama olarak; çerezler aşağıdaki şekilde
              gruplandırılabilir.
            </p>
            <p>
              <b>Kullanım süresine göre çerez çeşitleri:</b> Kullanım sürelerine
              göre çerezler, <b>oturum çerezleri</b> ve
              <b>kalıcı çerezler</b> olarak ikiye ayrılmaktadır. Oturum çerezi,
              tarayıcınızı kapattığınızda sona erer. Kalıcı çerez ise sabit
              diskinizde uzun bir süre veya süresiz olarak kalır. Halihazırda
              Site’de oturum çerezi kullanılmaktadır.
            </p>
            <p>
              <b>
                Çerezin sahibi veya çerezi yerleştiren tarafa göre çerez
                çeşitleri:
              </b>
              Site’de yerleştiren tarafa göre “Unipoly çerezleri (birinci taraf
              çerezler/first party cookie)” ve "üçüncü taraf (third party
              cookie) çerezler" ikiye ayrılmaktadır. Unipoly çerezleri, Unipoly
              tarafından oluşturulurken, üçüncü taraf çerezleri ise iş birliği
              yaptığımız üçüncü taraflar tarafından yönetilmektedir. Halihazırda
              Site’de birinci taraf çerezler kullanılmaktadır.
            </p>
            <p>
              <b>Kullanım amaçlarına göre çerez çeşitleri:</b> Kullanım amacına
              göre çerezler olarak; kesinlikle gerekli çerezler (zorunlu
              çerezler), işlevsel çerezler, performans/analitik çerezler ve
              reklam/pazarlama çerezleri kullanılmaktadır. Halihazırda Site’de
              performans/analitik çerezler kullanılmaktadır.
            </p>
            <p>
              Site’de yer alan çerezlere ilişkin bilgiler aşağıdaki tablolarda
              yer almaktadır:
            </p>
            <table border={1}>
              <tbody>
                <tr>
                  <th>Çerez Hizmeti Sağlayıcısı ve Çerez Adı :</th>
                  <th>Unipoly chakra-ui-color- mode</th>
                </tr>
                <tr>
                  <th>Çerezin Amacı ve Çerez Aracılığıyla İşlenen Veriler :</th>
                  <th>
                    Ziyaretçilerin web sitesinde dolaşması sırasında renk moduna
                    bağlı olarak herhangi bir değeri veya stili değiştirmek için
                    kullanılır.
                  </th>
                </tr>
                <tr>
                  <th>Çerezin Türü :</th>
                  <th>Performans/Analitik Çerezler</th>
                </tr>
                <tr>
                  <th>Çerezin Süresi ve Çeşidi :</th>
                  <th>[●] – Oturum Çerezi</th>
                </tr>
              </tbody>
            </table>
            <h2>3. Hukuki Sebepler</h2>
            <table border={1}>
              <tbody>
                <tr>
                  <th>Çerezin Türü</th>
                  <th>Hukuki Sebepler</th>
                </tr>
                <tr>
                  <td>Performans/Analitik Çerezler</td>
                  <td> Açık rızanız</td>
                </tr>
              </tbody>
            </table>
            <h2>4. Çerezler Aracılığıyla İşlenen Verilerinizin Aktarılması</h2>
            <p>
              Unipoly, kişisel verilerinizi “bilme gereği” ve “kullanma gereği”
              ilkelerine uygun olarak, gerekli veri minimizasyonunu sağlayarak
              ve gerekli teknik ve idari güvenlik tedbirlerini alarak işlemeye
              özen göstermektedir. İş ortaklığı süreçlerinin niteliği, mal ve
              hizmet temin süreçlerinin gereklilikleri, tedarik zinciri yönetim
              süreçlerinin yönetimi gibi süreçlerinin yürütülmesi veya denetimi,
              dijital altyapıların işletilmesi farklı paydaşlarla sürekli veri
              akışını zaruri kıldığı için çerezler aracılığıyla işlediğimiz
              kişisel verileri belirli amaçlarla üçüncü kişilere aktarmak
              durumundayız.
            </p>
            <p>
              Bu metin kapsamında işlenen kişisel verileriniz, yukarıda
              belirtilen amaçların gerçekleştirilmesi doğrultusunda ve bu
              amaçların yerine getirilmesi ile sınırlı olarak;
              hissedarlarımızla, iş ortaklarımızla, iş faaliyetlerinin
              yürütülmesi ve denetimi, iş sürekliliğinin sağlanması
              faaliyetlerinin yürütülmesi, bilgi güvenliği süreçlerinin
              yürütülmesi amaçlarıyla; çerez yönetimi altyapımızı sağlayan,
              işleten veya hizmet sunan iş ortaklarımızla ve hizmet
              sağlayıcılarımızla, hizmetlerin kalite kontrol, şikayet yönetimi
              ve risk analizi alanında hizmet sunan iş ortaklarımızla ve hizmet
              sağlayıcılarımızla, kanunen yetkili kamu kurumları ve özel kişi
              veya kuruluşlar ile üçüncü kişilere, veri sorumlusunun meşru
              menfaatinin gerektirdiği hallerde özel olarak belirlenecek üçüncü
              kişilerle, işbu metinde belirtilen amaçlarla sınırlı olarak
              aktarılabilecek, yurt içinde veya yurt dışında işlenebilecektir.
            </p>
            <h2>5. Çerez Yönetimi</h2>
            <p>
              Çerez tercihlerinizi istediğiniz zaman [Tercihleri Yönet]
              simgesine tıklayarak değiştirebilirsiniz. Ardından mevcut
              kaydırıcıları "Açık" veya "Kapalı" olarak ayarlayabilir ve
              ardından "Kaydet ve kapat"ı tıklayabilirsiniz. Ayarlarınızın
              etkili olması için sayfanızı yenilemeniz gerekebilir. Ayrıca,
              internet tarayıcınızın çeşidine göre aşağıdaki adımları izleyerek
              de çerezler hakkında bilgi edinip, izin verme veya reddetme
              hakkınızı kullanabilirsiniz:
            </p>
            <ul>
              <li>
                Google Chrome: Browser’ınızın “adres bölümünde” yer alan, “kilit
                işareti” veya “i” harfini tıklayarak, “Cookie” sekmesinden
                çerezlere izin verebilir veya bloke edebilirsiniz.
              </li>
              <li>
                Mozilla Firefox: Browser’ınızın sağ üst köşesinde yer alan
                “menüyü aç” sekmesini tıklayınız. “Seçenekler” görselini
                tıklayarak “Gizlilik ve Güvenlik” butonunu kullanarak çerez
                yönetiminizi yapınız.
              </li>
              <li>
                Diğer browserlar için de (opera, microsoft edge gibi), ilgili
                browser’ın yardım veya destek sayfalarını inceleyebilirsiniz.
              </li>
              <li>
                Safari: Telefonunuzun “Ayarlar” bölümünden “safari” sekmesini
                seçip, “Gizlilik ve Güvenlik” Bölümünden tüm çerez yönetiminizi
                sağlayabilirsiniz.
              </li>
              <li>
                Yukarıdaki seçeneklerin yanısıra; tüm çerezler hakkında bilgi
                sahibi olmak ve çerez yönetimi için:
                <a
                  href="https://www.allaboutcookies.org"
                  style={{ color: '#fffa2b' }}
                >
                  https://www.allaboutcookies.org
                </a>
                ,
                <a
                  href="https://www.youronlinechoices.eu/"
                  style={{ color: '#fffa2b' }}
                >
                  https://www.youronlinechoices.eu/
                </a>
                adresini ziyaret edebilirsiniz veya "Privacy Badger"
                uygulamasını kullanabilirsiniz
                <a
                  href="https://www.eff.org/tr/privacybadger"
                  style={{ color: '#fffa2b' }}
                >
                  (https://www.eff.org/tr/privacybadger)
                </a>
              </li>
              <li>
                Mobil uygulamalarda çerez veya SDK yönetimi için cihazınızın
                Gizlilik veya Ayarlar Bölümünde yer alan yönlendirmeleri takip
                edebilir veya Lumen Privacy Monitor’ü
                <a href="https://haystack.mobi" style={{ color: '#fffa2b' }}>
                  (https://haystack.mobi)
                </a>
                telefonunuza indirerek kullanabilirsiniz.
              </li>
            </ul>
            <p>
              Oturum çerezini reddederseniz, Site’yi kullanmaya devam
              edebilirsiniz fakat Site’nin tüm işlevlerine erişemeyebilirsiniz
              veya erişiminiz sınırlı olabilir.
            </p>
            <h2>6. İlgili Kişi Olarak Haklarınız</h2>
            <p>Kişisel verilerin korunmasına ilişkin mevzuat kapsamında;</p>
            <ul>
              <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme,</li>
              <li>
                Kişisel verilerinizin işlenmesi halinde buna ilişkin bilgi talep
                etme,
              </li>
              <li>
                Kişisel verilerinizin işlenme amacını ve bunların amacına uygun
                kullanılıp kullanılmadığını öğrenme,
              </li>
              <li>
                Yurt içinde veya yurt dışında kişisel verilerinin aktarıldığı
                üçüncü kişileri bilme,
              </li>
              <li>
                Kişisel verilerinizin eksik veya yanlış işlenmiş olması ya da
                değişmesi halinde bunların düzeltilmesini isteme,
              </li>
              <li>
                Kişisel Verilerin Korunması Kanunu’nda öngörülen koşullara uygun
                olarak kişisel verilerinizin silinmesini veya yok edilmesini
                isteme,
              </li>
              <li>
                Eksik veya yanlış olarak işlenmiş kişisel verilerinizin
                düzeltildiğinin ve kişisel verilerinizin silindiğinin yahut yok
                edildiğinin kişisel verilerinin aktarıldığı üçüncü kişilere
                bildirilmesini isteme,
              </li>
              <li>
                İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla
                analiz edilmesi suretiyle aleyhinize bir sonuç ortaya çıkmasına
                itiraz etme,
              </li>
              <li>
                Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle
                zarara uğramanız halinde zararın giderilmesini talep etme
                hakkınız bulunmaktadır.
              </li>
            </ul>
            <p>
              İlgili kişiler olarak, haklarınıza ilişkin taleplerinizi belirtmek
              ve kişisel verileriniz üzerindeki haklarınızı kullanmak amacıyla;
              Esentepe Mah. Haberler Sk. No: 1 İç Kapı No: 1 Şişli / İstanbul
              adresine fiziken yazılı olarak, tarafınıza tanımlı olan güvenli
              elektronik imzayla veya mobil imzayla imzalanarak, KEP adresiniz
              vasıtasıyla Şirketimiz kayıtlı elektronik posta adresi olan [●]
              adresine e-posta göndererek veya elektronik posta adresinizi
              kullanmak suretiyle [●] adresimize e-posta göndererek; gerekli
              değişiklik, güncelleme ve/veya silme gibi işlemleri ve ilgili
              talepleri gerçekleştirebilirsiniz.
            </p>
            <p>
              İlgili Kişiler olarak sahip olduğunuz ve yukarıda belirtilen
              haklarınızı kullanmak için yapacağınız ve kullanmayı talep
              ettiğiniz hakka ilişkin açıklamalarınızı içeren başvuruda; talep
              ettiğiniz hususun açık ve anlaşılır olması, talep ettiğiniz
              konunun şahsınız ile ilgili olması veya başkası adına hareket
              ediyor iseniz bu konuda özel olarak yetkili olmanız ve yetkinizi
              belgelendirilmesi, başvurunun Veri Sorumlusuna Başvuru Usul ve
              Esasları Hakkında Tebliğ'de yer alan asgari başvuru şartlarını
              taşıması gerekmektedir. Kanun’un 13. maddesinin 1. fıkrası
              gereğince, yukarıda belirtilen haklarınızı kullanmak ile ilgili
              talebinizi, "Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında
              Tebliğ"e istinaden aşağıdaki bilgiler ile gerçekleştirebilirsiniz:
            </p>
            <ul>
              <li>Başvuru sahibinin adı soyadı,</li>
              <li>
                Başvuru sahibi Türkiye Cumhuriyeti vatandaşı ise T.C. Kimlik
                Numarası, değil ise uyruğu ile birlikte pasaport numarası veya
                var ise kimlik numarası,
              </li>
              <li>
                Başvuru sahibinin tebligata esas yerleşim yeri veya iş yeri
                adresi,
              </li>
              <li>
                Başvuru sahibinin bildirime esas elektronik posta adresi,
                telefon veya faks numarası,
              </li>
              <li>Talep konusu,</li>
              <li>Talep konusuna istinaden bilgi ve belgeler,</li>
              <li>Başvuru yöntemleri ve</li>
              <li>Başvuru yazılı ise imza.</li>
            </ul>
            <p>
              Belirtilen yöntemlerle taleplerinizi bize iletmeniz durumunda
              Şirket, talebinizin niteliğine göre talebi en kısa sürede ve en
              geç otuz gün içinde ücretsiz olarak sonuçlandıracaktır. Ancak,
              işlemin ayrıca bir maliyeti gerektirmesi hâlinde, Şirket
              tarafından Kişisel Verileri Koruma Kurulu’nca belirlenen
              tarifedeki ücret alınacaktır.
            </p>
            <p>
              Şirket Unvanı : Unıpoly Yazılım ve Teknoloji Anonim Şirketi
              Unipoly
              <br />
              Adres : Esentepe Mah. Haberler Sk. No: 1 İç Kapı No: 1 Şişli /
              İstanbul
            </p>
          </div>
        </div>
      </section>

      <br />

      <Divider borderColor={'rgba(23, 27, 30) !important'} />

      <Footer />
    </Box>
  );
}
