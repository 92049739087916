'use client';
import {
  Stack,
  Flex,
  Heading,
  Text,
  Button,
  AspectRatio,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import './gamecard.css';
import rf3 from '../../image/rf3.webp';

function Gamerf3() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Stack
      direction={{ base: 'row', md: 'row' }}
      flexWrap={['wrap', 'wrap', 'nowrap', 'nowrap', 'nowrap', 'nowrap']}
      maxW={{ base: 'full', lg: '768px' }}
      height={{ base: '570px', md: '350px' }}
      w={'full'}
      p={9}
      overflow={'hidden'}
      mb={[1, 1, 0]}
      className="gameCard"
      justify={{ base: 'center', md: 'start' }}
    >
      <Flex direction={'column'} maxW={{ base: '100%', md: '50%' }}>
        <Heading color={'white'} fontSize={'40px'} w={'100%'}>
          RaidField 3
        </Heading>
        <Text mt={5} color={'white'} textAlign={'left'} fontWeight={'normal'}>
          Venture into the final frontier with RaidField 3. Traverse the
          galaxies, partake in intense cosmic combat, and navigate through a
          myriad of riveting quests that span across planets and star systems.
        </Text>
      </Flex>
      <Flex width={{ base: '100%', sm: '80%', md: '100%' }} justify={'center'}>
        <Flex justify={'center'} rounded={'20px'}>
          <Image
            src={rf3}
            onClick={onOpen}
            h={'100%'}
            w={'100%'}
            rounded={'20px'}
          />
          <Modal isOpen={isOpen} size={'2xl'} onClose={onClose}>
            <ModalOverlay />
            <ModalContent
              bg={
                'radial-gradient(circle, rgba(71, 71, 71, 0.50024509803921573) 0%, rgba(0, 0, 0, 0.9243347338935574) 1.1%)'
              }
            >
              <ModalHeader color={'white'}>RaidField 3</ModalHeader>
              <ModalCloseButton color={'white'} />
              <ModalBody>
                <AspectRatio ratio={16 / 9}>
                  <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/7txLFlw4hqk?si=c0SCH5vaWid9LIhD"
                    title="RaidField 3"
                    allowFullscreen
                  ></iframe>
                </AspectRatio>
              </ModalBody>
              <ModalFooter>
                <Button bg="#fff2ab" mr={3} onClick={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Flex>
      </Flex>
    </Stack>
  );
}

export default Gamerf3;
