import { Box, Heading, Stack, Text } from '@chakra-ui/react';

export default function Card({ heading, description }) {
  return (
    <Box
      maxW={{ base: 'full', md: '275px' }}
      w={'full'}
      overflow="hidden"
      p={10}
    >
      <Stack align={'center'} spacing={2}>
        <Box mt={2}>
          <Text mt={1} fontWeight={'600'} fontSize={'44px'} color={'#fffa2b'}>
            {description}
          </Text>
          <Heading fontSize={'14px'} fontWeight={'400'} color={'white'}>
            {heading}
          </Heading>
        </Box>
      </Stack>
    </Box>
  );
}
