/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import '../cookie/cookie.css';

function CookieBanner() {
  const acceptCookieConsent = () => {
    localStorage.setItem('cookieConsentAccepted', 'true');
    hideCookieBanner();
  };

  const hideCookieBanner = () => {
    const cookiebanner = document.getElementById('cookiebanner');
    cookiebanner.style.display = 'none';
  };

  useEffect(() => {
    const isCookieConsentAccepted = localStorage.getItem(
      'cookieConsentAccepted'
    );
    if (isCookieConsentAccepted === 'true') {
      hideCookieBanner();
    }
  }, []); // componentDidMount

  return (
    <div id="cookiebanner" className="m-cookie">
      <div className="wrapper">
        <div className="m-cookie__wrap">
          <svg
            className="m-cookie__angle"
            xmlns="http://www.w3.org/2000/svg"
            width={44}
            height={122}
            viewBox="0 0 44 122"
          >
            <line
              x1={156}
              x2={116}
              y1={32}
              y2={152}
              fill="none"
              stroke="#fff"
              strokeWidth={4}
              transform="translate(-114 -31)"
            />
          </svg>
          <div className="m-cookie__left">
            <h2 className="m-cookie__title">Your privacy on Unipoly Coin</h2>
            <p className="m-cookie__text">
              We use cookies on your device to help us provide a more reliable
              service. We are only using essential cookies for website to
              function properly. For more information:&nbsp;
              <a href="/legal/cookies"> Unipoly Coin/Cookies </a>
            </p>
          </div>
          <div className="m-cookie__right">
            <a
              className="m-cookie__button m-cookie__button--secondary"
              // eslint-disable-next-line no-script-url
              href="javascript:void(0)"
              onClick={acceptCookieConsent}
            >
              Accept Cookies
            </a>
          </div>
        </div>
        <div className="m-modal__overlay" />
      </div>
    </div>
  );
}

export default CookieBanner;
