import React from 'react';
import Game from './game.js';
import Gamescard from './gamescard.js';
import { Box, Container } from '@chakra-ui/react';
import bg from '../../image/bg/1.webp';

function Gamecontainer() {
  return (
    <Box
      marginBottom={6}
      backgroundImage={{ base: 'none', md: bg }}
      backgroundSize={'cover'}
    >
      <Game />
      <Container maxW={'82rem'}>
        <Gamescard />
      </Container>
    </Box>
  );
}

export default Gamecontainer;
