import React from 'react';

import './timeline.css';
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';

import { ListIcon, List, ListItem } from '@chakra-ui/react';
function Timeline() {
  return (
    <div>
      <ul className="timeline">
        {/* -----left---- */}

        <li className="timeline-event  ">
          <label className="timeline-event-iconL" />
          <div className="timeline-event-copyL">
            <p className="timeline-event-thumbnailL">Q4 2023</p>
            <List>
              <ListItem fontSize={'20px'} color="white">
                DEX Listing Announcement
                <ListIcon ml={2} as={FaCheckCircle} color="#28a745" />
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                Users Reward System Announcement
                <ListIcon ml={2} as={FaCheckCircle} color="#28a745" />
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                Partnership and Marketing activities
                <ListIcon ml={2} as={FaCheckCircle} color="#28a745" />
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                Test gameplay mechanics and user experience.
                <ListIcon ml={2} as={FaCheckCircle} color="#28a745" />
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                Launch on Unipoly Web3 Launcher Platform
                <ListIcon ml={2} as={FaCheckCircle} color="#28a745" />
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                New CEX Listing Negotiations Start
                <ListIcon ml={2} as={FaCheckCircle} color="#28a745" />
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                Listing on Bzetmex
                <ListIcon ml={2} as={FaCheckCircle} color="#28a745" />
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                Launchpad on Bzetmex Exchange
                <ListIcon ml={2} as={FaCheckCircle} color="#28a745" />
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                Complete audit and testing of the smart contract.
                <ListIcon ml={2} as={FaCheckCircle} color="#28a745" />
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                Launch the project's website and social media channels
                <ListIcon ml={2} as={FaCheckCircle} color="#28a745" />
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                Making a project announcement
                <ListIcon ml={2} as={FaCheckCircle} color="#28a745" />
              </ListItem>
            </List>
          </div>
        </li>

        {/* ------right----- */}
        <li className="timeline-event">
          <label className="timeline-event-iconR" />
          <div className="timeline-event-copyR">
            <p className="timeline-event-thumbnailR">Q1 2024</p>
            <List>
              <ListItem fontSize={'20px'} color="white">
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
                New T2 and T1 Cex and Dex Listing Negotiations Start
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
                Unipoly Platform Updates V2
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
                Unipoly Web3 Inclusion of new games on the platform.
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
                Introduction of the Stake& Earn system
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
                Editing in-game Web3 integrations
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
                Partnership and Marketing activities
              </ListItem>
            </List>
          </div>
        </li>

        {/* -----left---- */}

        <li className="timeline-event  ">
          <label className="timeline-event-iconL" />
          <div className="timeline-event-copyL">
            <p className="timeline-event-thumbnailL">Q2 2024</p>
            <List>
              <ListItem fontSize={'20px'} color="white">
                New T1 Cex Listing Negotiations Start
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                Begin active development of Unipoly Chain, working on its
                architecture, consensus mechanism, and unique features.
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                Incorporating Web3-based projects into the platform.
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                Encourage user-generated content and engagement within the
                Unipoly community.
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                Gather user feedback for improvements
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
              </ListItem>
            </List>
          </div>
        </li>
        {/* ------right----- */}
        <li className="timeline-event">
          <label className="timeline-event-iconR" />
          <div className="timeline-event-copyR">
            <p className="timeline-event-thumbnailR">Q3 2024</p>
            <List>
              <ListItem fontSize={'20px'} color="white">
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
                Enhance gamification features in Unipoly games.
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
                Implement non-fungible tokens (NFTs) for unique in-game items
                and characters.
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
                New T2 and T1 Cex and Dex Listing Negotiations Start
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
                Launch the Token mobile app for greater accessibility.
              </ListItem>
            </List>
          </div>
        </li>

        {/* -----left---- */}
        <li className="timeline-event  ">
          <label className="timeline-event-iconL" />
          <div className="timeline-event-copyL">
            <p className="timeline-event-thumbnailL">Q4 2024</p>
            <List>
              <ListItem fontSize={'20px'} color="white">
                Unipoly marketplace system announcement
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                Buyback and Burn Mechanism Announcement
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                Unipolychain Testnet Launch
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                Partnership and Marketing activities
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                Start airdrop for Testnet
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
              </ListItem>
            </List>
          </div>
        </li>

        {/* -----left---- */}
      </ul>
    </div>
  );
}

export default Timeline;
