'use client';
import { Stack } from '@chakra-ui/react';
import { useInView, animated } from '@react-spring/web';
import Gamescm from './gamescm.js';
import Gamerf3 from './gamerf3.js';

function Gamescard() {
  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        x: -100,
      },
      to: {
        opacity: 1,
        x: 0,
      },
    }),
    { once: true }
  );
  return (
    <animated.div ref={ref} style={springs}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        flexWrap={['wrap', 'wrap', 'wrap', 'nowrap', 'nowrap', 'nowrap']}
      >
        <Gamerf3 />
        <Gamescm />
      </Stack>
    </animated.div>
  );
}

export default Gamescard;
