'use client';

import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';

import { Divider, Box } from '@chakra-ui/react';

import '../legal/legal.css';

export default function Privacy() {
  return (
    <Box background={'#010810'}>
      <Navbar />
      <br />

      <section className="legal-section">
        <div className="legal-wrapper">
          <div className="legal-header">
            <p>Privacy policy</p>
            <hr />
          </div>
          <br />
          <div className="text-wrapper">
            <h2>PRIVACY POLICY AND PERSONAL DATA</h2>
            <p>
              <strong>Unipoly Games</strong> This Privacy Policy and Personal
              Data Text (Policy) is prepared for the purpose of informing Data
              Subjects on terms and conditions regarding the use of the data
              collected or to be collected and the ways in which the data is
              processed from Data Subjects in any method and/or Users accessing
              applications that they can install on their devices through
              https://unipolygames.com/ domain website and virtual stores and/
              or the persons who visit the Website managed and operated by
              Unıpoly Yazılım ve Teknoloji Anonim Şirketi (Unipoly or Company).
            </p>
            <h2>Definitions and General Explanations</h2>
            <p>Terms used herein shall have the following meanings:</p>
            <p>
              <span> Website: </span>The website
              <a href="“https://unipolygames.com/”">
                “https://unipolygames.com/”
              </a>
              owned by Unipoly or the website operated by Unipoly under another
              domain name.
            </p>
            <p>
              <span> Application(s): </span> Applications released by Unipoly
              that Users install on their devices through virtual stores,
            </p>
            <p>
              <span> Platform: </span> Separately or together, Website and
              Application,
            </p>
            <p>
              <span> Visitor(s): </span>Person who uses the Website without
              creating an account, and/or who visits the Website,
            </p>
            <p>
              <span> Player(s): </span>Person who accesses the Application by
              creating an account
            </p>
            <p>
              <span> User(s): </span>Separately or together, Visitor and Player,
            </p>
            <p>
              <span> Data Subject(s): </span> Separately or together, Visitor or
              User whose personal data is processed,
            </p>
            <p>
              <span></span>
            </p>
            <p>
              <span> User(s): </span>Terms of Use on the Platform that Data
              Subjects have accepted by accessing the Platform.
            </p>
            <p>
              Unipoly adopts this Policy regarding the confidentiality, use and
              other related issues of the information processed regarding Data
              Subjects. Personal data refers to all the information relating to
              an identified or identifiable natural person. For this reason,
              provisions regarding personal data in this text will be applied if
              the relevant information belongs to a natural person. If the
              relevant information belongs to a legal person, provisions apart
              from provisions related to personal data in this text shall be
              applied. Unipoly regards the confidentiality of data and takes
              care to be transparent about the storage of information. This
              Policy is the privacy policy that covers information about what
              kind of data is collected, how this data is used, with whom this
              data is shared, if necessary, what are the rights regarding
              personal data and how these rights can be exercised and the
              principles adopted by Unipoly relating to confidentiality.
              Platform may contain links to other websites unknown to Unipoly,
              whose content is not checked. These linked websites may contain
              different terms and conditions than texts belonging to Unipoly.
              Unipoly cannot be held responsible for the use or disclosure of
              information that these websites may process. Likewise, Unipoly
              will not have any liability when a link is provided to Unipoly’s
              Platform from other websites.
            </p>
            <p>
              Personal data is processed in accordance with the following
              principles:
            </p>
            <ul>
              <li>Lawfulness and fairness,</li>
              <li>Being accurate and keeping up to date where necessary,</li>
              <li>
                Being processed for specified, explicit, and legitimate
                purposes,
              </li>
              <li>
                Being relevant, limited and proportionate to the purposes for
                which they are processed,
              </li>
              <li>
                Being stored for the period&nbsp;laid down&nbsp;by relevant
                legislation or the period required for the purpose for which the
                personal data are processed.
              </li>
            </ul>
            <h3>Enlightening/Informing the Data Subject</h3>
            <p>
              With this Policy, Unipoly aims to enlighten/inform the natural
              persons whose data will be processed during the acquisition of
              personal data on the following aspects:
            </p>
            <ul>
              <li>
                The identity of the data controller and of its representative,
                if any,
              </li>
              <li>The purpose of data processing,</li>
              <li>
                To whom and for which purposes the processed personal data may
                be transferred,
              </li>
              <li>
                The method and legal basis of collection of personal data,
              </li>
              <li>The rights of Data Subject.</li>
            </ul>
            <p>
              In this context, Unipoly aims to make the necessary clarification.
              Unipoly is the data controller in accordance with the Law only for
              the personal data of the Data Subjects, whose purposes and legal
              causes are clearly stated in this Policy.
            </p>
            <ul className="ul-header">
              <li>
                Collection of Personal Data, Method of Collection and Lawful
                Basis
              </li>
            </ul>
            <p>
              Information that Data Subjects send to, share with Unipoly and/or
              store in an accessible form while using the Platform, and
              information that is collected by automatic means through Platform,
              may be included in the scope of personal data. If you access the
              Platform, we collect the following information about you:
            </p>
            <ul className="ul-header">
              <li>Identity and contact information:</li>
            </ul>
            <p>
              Name-surname, username as identification information and e-mail
              address as contact information are optionally processed by you
              accessing the Raidfield Application. In addition, you will be able
              to share their name-surname, username and e-mail address
              information to convey your requests/complaints with Unipoly.
            </p>
            <ul className="ul-header">
              <li>Usage data:</li>
            </ul>
            <p>
              When you access the Platform, usage data is collected about you.
              These data may include you visiting the Platform, what you click
              on, when you perform these actions, etc. Also, like many websites
              today, our network servers keep log files; these files save data
              every time a device accesses them. Log files contain data on the
              nature of each access, such as source IP addresses, internet
              service providers, files displayed on your site (e.g. HTML pages,
              graphics, etc.), operating system versions and timestamps.
            </p>
            <ul className="ul-header">
              <li>Device data:</li>
            </ul>
            <p>
              Data such as your IP address, operating system version, device
              type, system and performance information, and browser type are
              collected from the devices and applications you use to access the
              Platform.
            </p>
            <p>
              In Applications where this feature is provided, you can save the
              progress you have made in the Application to the accounts of
              application stores such as Facebook, Apple ID, Instagram and they
              can continue from the progress point on another device by
              connecting accounts of the application stores such as Facebook,
              Apple ID, Instagram to the Application on another device and
              transferring this progress. In this context, the privacy
              policies/notices of the related application stores shall be
              applied in addition to this Policy to personal data processed by
              those application stores. If you make an in-app purchase, your
              payment information is not shared with Unipoly by the virtual
              store you purchased from. Unipoly does not process your payment
              information and other information related to your payment (card
              number, account number, etc.) in any way. In the case of such
              in-app purchases, the terms and conditions of the related
              application stores such as AppStore, Play Store etc. shall be
              applied.
            </p>
            <hr />
            <ul className="ul-header">
              <li>Data Categories and Data Types</li>
            </ul>
            <table className="tg">
              <thead>
                <tr>
                  <td className="tg-cly1" rowSpan={7}>
                    Player
                  </td>
                  <td className="tg-cly1">Identity</td>
                  <td className="tg-cly1">Name surname, Username</td>
                </tr>
                <tr>
                  <td className="tg-cly1">Contact</td>
                  <td className="tg-cly1">E-mail Address</td>
                </tr>
                <tr>
                  <td className="tg-cly1">Location</td>
                  <td className="tg-cly1">General Location Data(Country)</td>
                </tr>
                <tr>
                  <td className="tg-cly1">Social Media Accounts</td>
                  <td className="tg-cly1">
                    Facebook, Apple ID, Instagram account information
                  </td>
                </tr>
                <tr>
                  <td className="tg-cly1">Customer Transaction</td>
                  <td className="tg-cly1">
                    Application progress and information of level within the
                    games, Metamask Wallet data
                  </td>
                </tr>
                <tr>
                  <td className="tg-cly1">Process Security</td>
                  <td className="tg-cly1">
                    Data collected through pc/e-mail/system and application user
                    transaction information, internet traffic data (network
                    movements, IP address, visit data, time and date
                    information) device name, in-app purchase history, the
                    identifier for advertisers designated in your mobile device
                    used in accessing our services (if you give permission, the
                    Identifier for Advertisers-IDFA), the identifier for
                    vendors/developers designated your mobile device (The
                    Identifier for Vendors-IDVF)
                  </td>
                </tr>
                <tr>
                  <td className="tg-cly1">Marketing Data</td>
                  <td className="tg-cly1">IDFA, IDVF</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="tg-cly1" rowSpan={2}>
                    Visitor
                  </td>
                  <td className="tg-cly1">Process Security</td>
                  <td className="tg-cly1">
                    Data collected through pc/e-mail/system and application user
                    transaction information, internet traffic data (network
                    movements, IP address, visit data, time and date
                    information)
                  </td>
                </tr>
                <tr>
                  <td className="tg-cly1">Location</td>
                  <td className="tg-cly1">General location data (country)</td>
                </tr>
              </tbody>
            </table>
            <ul>
              <li>Lawful Basis</li>
            </ul>
            <p>
              Your personal data may be processed for the purposes that are the
              subject of your disclosure to Unipoly and for the purposes listed
              below in terms of each data category, in accordance with the
              relevant legislation. The lawful basis for each data category are
              explicitly stated below:
            </p>
            <table className="tg">
              <thead>
                <tr>
                  <td className="tg-cly1" rowSpan={4}>
                    Player
                  </td>
                  <td className="tg-cly1">
                    Identity Information
                    <br />
                    Contact Information
                    <br />
                    Social Media Accounts
                    <br />
                    Location
                  </td>
                  <td className="tg-cly1">
                    • Processing is necessary for the establishment, exercise or
                    protection of any right <br />• Processing is necessary for
                    our legitimate interests, provided that the fundamental
                    rights and freedoms of data subjects are not harmed <br />•
                    In situations where it is necessary to process the data
                    subjects’ personal data, in order to build a contractual
                    relationship or processing is directly related to the
                    execution or performance of the agreement.
                  </td>
                </tr>
                <tr>
                  <td className="tg-cly1">Marketing Data</td>
                  <td className="tg-cly1">•Your explicit consent</td>
                </tr>
                <tr>
                  <td className="tg-cly1">Customer Transaction</td>
                  <td className="tg-cly1">
                    • In situations where it is necessary to process the data
                    subjects’ personal data, in order to build a contractual
                    relationship or processing is directly related to the
                    execution or performance of the agreement
                  </td>
                </tr>
                <tr>
                  <td className="tg-cly1">Process Security</td>
                  <td className="tg-cly1">
                    • Processing is expressly provided for by the laws <br />•
                    Processing is necessary for our compliance with a legal
                    obligation
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="tg-cly1" rowSpan={2}>
                    Visitor
                  </td>
                  <td className="tg-cly1">Process Security</td>
                  <td className="tg-cly1">
                    • Processing is expressly provided for by the laws <br />•
                    Processing is necessary for our compliance with a legal
                    obligation
                  </td>
                </tr>
                <tr>
                  <td className="tg-cly1">Location</td>
                  <td className="tg-cly1">
                    • It is necessary to process your personal data for our
                    legitimate interests, provided that your fundamental rights
                    and freedoms are not harmed. <br />• Processing is necessary
                    for the establishment, exercise or protection of any right.
                  </td>
                </tr>
              </tbody>
            </table>
            <ul className="ul-header">
              <li>For What Purposes Your Personal Data Is Processed</li>
            </ul>
            <p>
              The information collected by Unipoly can be used collectively in
              various ways in order to provide services to you by Unipoly, to
              optimize and improve the services, and to improve the service
              offered to those who access the Platform through this database by
              enriching the database. Unipoly may use this information for
              website and application management, security, research and
              analysis. Within the scope of this text, Data Subjects’ personal
              data is processed for the following purposes in compliance with
              the general conditions specified above:
            </p>
            <table className="tg">
              <thead>
                <tr>
                  <td className="tg-cly1" rowSpan={6}>
                    Player
                  </td>
                  <td className="tg-cly1">
                    Identity Information
                    <br />
                    Contact Information
                    <br />
                    Social Media Accounts
                  </td>
                  <td className="tg-cly1">
                    • Carrying out the activities in accordance with legislation{' '}
                    <br />• Conducting of company/product/service loyalty
                    operations
                    <br />
                    • Conducting / audit of business activities <br />•
                    Conducting after-sales support services for goods/services
                    <br />• Conducting of customer relationship management
                    processes <br />• Conducting activities for customer
                    satisfaction <br />• Monitoring requests / complaints
                  </td>
                </tr>
                <tr>
                  <td className="tg-cly1">Location</td>
                  <td className="tg-cly1">
                    • Execution of company/product/service commitment operations{' '}
                    <br />• Conducting storage and archive activities <br />•
                    Execution of agreement processes
                  </td>
                </tr>
                <tr>
                  <td className="tg-cly1">Marketing Data</td>
                  <td className="tg-cly1">
                    • Conducting marketing analysis studies <br />• Execution of
                    advertising/campaign /promotion processes
                  </td>
                </tr>
                <tr>
                  <td className="tg-cly1">Customer Transaction</td>
                  <td className="tg-cly1">
                    • Conducting / audit of business activities <br />•
                    Conducting after-sales support services for goods/services
                    <br />• Conducting of good / service sale process <br />•
                    Conducting of agreement processes <br />• Conducting
                    business continuity <br />• Monitoring requests / complaints
                  </td>
                </tr>
                <tr>
                  <td className="tg-cly1">Process Security</td>
                  <td className="tg-cly1">
                    • Conducting of information security processes <br />•
                    Carrying out the activities in accordance with legislation
                    <br />• Conducting of supervision / ethical activities
                    <br />• Conducting of access authorization <br />•
                    Conducting / audit of business activities <br />• Conducting
                    business continuity <br />• Informing authorized person,
                    authority and organizations
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="tg-cly1" rowSpan={2}>
                    Visitor
                  </td>
                  <td className="tg-cly1">Process Security</td>
                  <td className="tg-cly1">
                    • Conducting of information security processes <br />•
                    Carrying out the activities in accordance with legislation
                    <br />• Conducting of supervision / ethical activities
                    <br />• Conducting of access authorization <br />•
                    Conducting / audit of business activities <br />• Conducting
                    business continuity <br />• Informing authorized person,
                    authority and organizations
                  </td>
                </tr>
                <tr>
                  <td className="tg-cly1">Location</td>
                  <td className="tg-cly1">
                    • Execution of company/product/service commitment operations{' '}
                    <br />• Conducting storage and archive activities <br />•
                    Execution of agreement processes
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              Every time you visit the Platform, your IP address, operating
              system, connection time and duration information and similar
              information are automatically recorded. It is possible to use this
              information anonymously, which is obtained without the need to
              take your consent.
            </p>
            <ul className="ul-header">
              <li>Technical and Administrative Measures</li>
            </ul>
            <p>
              Unipoly stores the personal data it processes in accordance with
              relevant legislation for periods stipulated in relevant
              legislation or required for the purpose of processing. Unipoly
              undertakes to take all necessary technical and administrative
              measures and to take the due care to ensure the confidentiality,
              integrity and security of personal data. In this context, it takes
              the necessary measures to prevent unlawful processing of personal
              data, unauthorized access to data, unlawful disclosure,
              modification or destruction of data. Accordingly, Unipoly takes
              the following technical and administrative measures regarding the
              personal data it processes:
            </p>
            <p>
              <span> Anti-virus application. </span> On all computers and
              servers in Unipoly's information technology infrastructure, a
              periodically updated anti-virus application is installed.
            </p>
            <p>
              <span> Firewall. </span> The data centre and disaster recovery
              centres hosting Unipoly servers are protected by periodically
              updated software-loaded firewalls; the relevant next-generation
              firewalls control the internet connections of all staff and
              provide protection against viruses and similar threats during this
              control.
            </p>
            <p>
              <span> User identifications. </span> Unipoly employees'
              authorization to Unipoly systems is limited only to the extent
              necessary by job descriptions; in case of any change of authority
              or duty, systemic authorizations are also updated.
            </p>
            <p>
              <span> Information Security Management System (ISMS). </span> At
              the ISMS meetings made within Unipoly, the topics contained in the
              control forum are audited monthly by the director of information
              technology and the director of financial operations.
            </p>
            <p>
              <span> Physical data security. </span> It ensures that personal
              data on papers is necessarily stored in lockers and accessed only
              by authorized persons.
            </p>
            <p>
              <span> Erasion of cookies. </span>Personal data processed through
              cookies belonging to third parties from which service is received
              are deleted from the systems of third parties if the membership is
              terminated.
            </p>
            <p>
              In the event that the personal data is damaged as a result of
              attacks on the Platform or on the Unipoly system, despite Unipoly
              taking the necessary information security measures, or the
              personal data is obtained by unauthorized third parties, Unipoly
              notifies this situation to Data Subjects’ immediately and, if
              necessary, to relevant data protection authority and takes
              necessary measures.
            </p>
            <ul className="ul-header">
              <li>Transfer of Your Personal Data</li>
            </ul>
            <p>
              Unipoly may transfer the personal data obtained from Data Subject
              to its domestic or overseas servers or suppliers in order to
              perform the services provided by Unipoly through the Platform and
              to fulfill the obligations specified in this Policy. Unipoly may
              also transfer personal data related to Data Subjects to third
              parties and for the following purposes:
            </p>
            <table>
              <tbody>
                <tr>
                  <th>Subject</th>
                  <th>Purpose of Transfer and Transferred Group</th>
                </tr>
                <tr>
                  <td rowSpan={6}>Player</td>
                  <td>
                    Sharing process security information with authorized public
                    institutions and organizations for the purpose of execution
                    of activities in compliance with legislation, monitoring and
                    execution of legal affairs, informing authorized persons,
                    institutions and organizations;
                  </td>
                </tr>
                <tr>
                  <td>
                    Sharing information with overseas suppliers that storage
                    services are received from, for the purpose of conducting
                    retention and archive activities;
                  </td>
                </tr>
                <tr>
                  <td>
                    Sharing process security and marketing information with
                    overseas suppliers and business partners for the purpose of
                    conducting marketing activities;
                  </td>
                </tr>
                <tr>
                  <td>
                    Sharing process security and customer transaction
                    information with domestic or overseas suppliers whose
                    services are used in business processes for the purpose of
                    conducting and auditing business activities;
                  </td>
                </tr>
                <tr>
                  <td>
                    Sharing customer transaction information with third-party
                    providers in case any transaction is linked to a wallet;
                  </td>
                </tr>
                <tr>
                  <td>
                    Sharing customer transaction and process security
                    information with Application developers, if any, within the
                    scope of the agreement and the development of the
                    Applications.
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2}>Visitor</td>
                  <td>
                    Sharing information with overseas suppliers that storage
                    services are received for the purpose of conducting
                    retention and archive activities;
                  </td>
                </tr>
                <tr>
                  <td>
                    Sharing information with authorized public institutions and
                    organizations for the purpose of informing authorized
                    persons, institutions and organizations.
                  </td>
                </tr>
              </tbody>
            </table>

            <p>
              In case the Data Subject is a citizen of the European Union, in
              the event that personal data is transferred to third parties
              located outside the European Economic Area (EEA) or Turkey,
              Unipoly takes the necessary security measures by confirming that
              that (i) the third parties are certified under the Privacy Shield
              or (ii) the Binding Company Rules approved by the relevant data
              protection authority are in force or (iii) Standard Contractual
              Clauses approved by the European Commission have been concluded
              with the third party.
            </p>
            <ul className="ul-header">
              <li>Retention of Personal Data</li>
            </ul>
            <p>
              In the event that the retention and processing conditions for the
              periods of processing of personal data obtained by Unipoly are
              ceased, the following criteria are used in determining the
              destruction periods:
            </p>
            <ul>
              <li>
                If the relevant legislation provides for a period of time for
                such personal data retention, this period shall be followed.
                After the expiration of the specified period, the necessary
                operations shall be performed.
              </li>
              <li>
                If no time is stipulated for the expiry of the period in the
                relevant legislation or for the retention of the data in
                question, principles shall be adopted for retention of the
                information , for example; it is questioned whether Unipoly has
                a legitimate purpose in the retention of data. Although it has
                been processed in accordance with the legislation, the data of
                which reasons requiring its processing are ceased, shall be
                deleted, destroyed or anonymized.
              </li>
            </ul>
            <ul className="ul-header">
              <li>Minor’s Privacy</li>
            </ul>
            <p>
              The Platform provided by Unipoly does not address anyone under the
              age of majority according to the applicable of such jurisdiction
              which is the country of the User located. Unipoly does not
              knowingly collect personally identifiable information from anyone
              under the age of majority and if you are a parent or guardian and
              you are aware that your minor has provided the Platform with
              Personal Data without your consent, you may contact Unipoly
              through email at [support@unipolygames.com]
            </p>
            <ul className="ul-header">
              <li>Changes and Updates</li>
            </ul>
            <p>
              As a data controller, Unipoly has the right to change the policy
              provided that it complies with the relevant legislation and that
              the personal data are better protected. This Policy can be
              rearranged and updated as new features are added to digital
              platforms or new suggestions are received from Data Subjects.
              However, in this case, we shall notify you by publishing the
              changes on the Platform. We may notify such changes, in some
              important cases, by e-mail or by any other conspicuous method
              reasonably designed to notify you, with additional notices as
              appropriate. Upon being notified about these changes, if you
              continue to access the Platform and use or access the Platform
              without benefiting from the services offered by Unipoly after the
              notification period, you shall be deemed to have allowed the
              changes in the Policy. If you do not agree to the terms of this
              Policy or of an updated privacy policy and personal data text, you
              reserve the right (if any) to close your account at any time or
              not to respond to surveys. Therefore, we recommend that you
              re-examine the policy every time you access to the Platform. This
              document was last updated on [30/12/2023]. If the Policy
              provisions change, they become effective on the date of
              publication.
            </p>
            <ul className="ul-header">
              <li>Your Rights as a Data Subject</li>
            </ul>
            <p>
              As data subjects, in order to express your requests regarding your
              rights and to exercise your rights on your personal data; file
              your request and take actions such as making necessary changes,
              updates and/or deleting by sending them to the Company's official
              e-mail address [support@unipolygames.com ] or to Unipoly's
              address, Esentepe Mah. Haberler Sk. No: 1 İç kapı no: 1
              Şişli/İstanbul; with following information:
            </p>
            <ul>
              <li>Applicant's name and surname,</li>
              <li>
                If the applicant is a citizen of the Republic of Turkey, the
                Turkish Identity Number, if not, the nationality with passport
                number or identity number if there is one,
              </li>
              <li>
                Applicant's residence or business address for correspondence,
              </li>
              <li>
                Applicant's e-mail address, telephone or fax number for
                correspondence,
              </li>
              <li>Subject of request,</li>
              <li>
                Information and documents related to the subject of request,
              </li>
              <li>Application methods and</li>
              <li>Signature if the application is written.</li>
            </ul>
            <p>
              If you submit your requests to us by the specified methods,
              Unipoly shall conclude the request as soon as possible and within
              thirty days at the latest, free of charge depending on the nature
              of your request. However, if the transaction requires an
              additional cost, a fee that can be requested by Unipoly in
              accordance with the relevant legislation shall be charged.
            </p>
            <p>
              In cases where the Personal Data Protection Law no. 6698 is
              applicable, data subjects have the following rights:
            </p>
            <ul>
              <ol style={{ listStyleType: 'lower-alpha' }}>
                to learn whether personal data is processed or not,
              </ol>
              <ol style={{ listStyleType: 'lower-alpha' }}>
                to request information on whether his/her personal data has been
                processed,
              </ol>
              <ol style={{ listStyleType: 'lower-alpha' }}>
                to learn the purpose of the processing of the personal data and
                whether this information is used within the intended purposes,
              </ol>
              <ol style={{ listStyleType: 'lower-alpha' }}>
                to know the third parties to whom the personal data is
                transferred in domestic or abroad
              </ol>
              <ol style={{ listStyleType: 'lower-alpha' }}>
                to request the rectification of the incomplete or inaccurate
                processed personal data, if any,
              </ol>
              <ol style={{ listStyleType: 'lower-alpha' }}>
                to request the erasure or destruction of the personal data under
                the conditions stipulated in Article 7 of the Personal Data
                Protection Law,
              </ol>
              <ol style={{ listStyleType: 'lower-alpha' }}>
                to request notifying third parties to whom the personal data has
                been transferred, the transactions made pursuant to
                subparagraphs (d) and (e),
              </ol>
              <ol style={{ listStyleType: 'lower-alpha' }}>
                to object to the processing, exclusively by automatic systems,
                of the personal data, which leads to an unfavorable consequence
                for the person
              </ol>
              <ol style={{ listStyleType: 'lower-alpha' }}>
                to request compensation for the damage arising from the unlawful
                processing of personal data.
              </ol>
            </ul>
            <p>
              Where GDPR is applicable, data subjects have the following rights:
            </p>
            <ul>
              <li>
                Right of access - Learning whether personal data is being
                processed and, if so, accessing your personal data and the
                information regarding the processing of your personal data,
              </li>
              <li>
                Right to correction -To request the correction of information
                that you believe is inaccurate or the completion of information
                that you believe is incomplete by Unipoly,
              </li>
              <li>
                Right to erase – To request erasure of personal data under the
                conditions stipulated in GDPR,
              </li>
              <li>
                The right to restrict processing - To request the restriction of
                the processing of personal data under the conditions stipulated
                in the GDPR,
              </li>
              <li>
                Right to object to processing - To object to the processing of
                personal data under the conditions stipulated in the GDPR,
              </li>
              <li>
                Right to data portability - To request the data collected by
                Unipoly to be transferred directly to another organization or
                under certain conditions,
              </li>
              <li>
                Objection to the occurrence of a result against the person
                himself/herself, by analyzing the processed data exclusively
                through automatic systems, including profiling.
              </li>
            </ul>
            <p>
              In the application that includes your explanations about the right
              you have as the data subject and exercise your rights stated above
              and that you request to exercise; your request must be explicit
              and understandable, if the subject of your request is related to
              you or if you are acting on behalf of someone else, you must be
              specially authorized in this regard and your authority must be
              documented, the application must contain the identity and address
              information and documents proving your identity must be attached
              to the application. In the scenario where transactions related to
              the Application/Platform take place on the blockchain, we would
              like to emphasize that the right to rectification or deletion
              cannot be applied due to technical impossibility. In this case,
              our Company will show its best effort to apply a protective
              measure for the User. The relevant text has been prepared in
              accordance with the Law on the Protection of Personal Data No.
              6698. If you believe that we or someone with whom we have
              transferred your data is violating your rights, you can file a
              complaint to the data protection authority in your country and to
              other competent supervisory authorities.
            </p>
            <p>
              <span>Company Title: </span> Unıpoly Yazılım ve Teknoloji Anonim
              Şirketi
            </p>
            <p>
              <span> Address: </span>Esentepe Mah. Haberler Sk. No: 1 İç kapı
              no: 1 Şişli/İstanbul
            </p>
            <p />
          </div>
        </div>
      </section>

      <br />

      <Divider borderColor={'rgba(23, 27, 30) !important'} />

      <Footer />
    </Box>
  );
}
