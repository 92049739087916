'use client';

import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';

import { Divider, Box } from '@chakra-ui/react';

import '../legal/legal.css';

export default function Commercial() {
  return (
    <Box background={'#010810'}>
      <Navbar />
      <br />
      <section className="legal-section">
        <div className="legal-wrapper">
          <div className="legal-header">
            <p>Commercial Communication Notice</p>
            <hr />
          </div>
          <br />
          <div className="text-wrapper">
            <p>
              Unipoly may contact the User for various purposes including
              providing information about the campaigns, advantages, promotions,
              advertisements, surveys, research and other customer satisfaction
              applications conducted by Unipoly over the User's contact
              information, including the mobile phone number and e-mail address
              shared by the User. In this context, the User expressly consents
              to be contacted by clicking the relevant button on the Platform.
              If the User does not want to be contacted in this context, she/he
              should contact Unipoly.
            </p>
            <p>
              The User can always refuse to receive commercial electronic
              messages free of charge by contacting Unipoly via the
              <a className="legal-link" href="[suppport@unipolygames.com]">
                [suppport@unipolygames.com]
              </a>
              e-mail address of Unipoly. The use of the right of refusal does
              not prevent the notifications that are required to be sent to you
              in accordance with the provisions of the relevant legislation to
              which Unipoly is subject.
            </p>
          </div>
        </div>
      </section>

      <br />

      <Divider borderColor={'rgba(23, 27, 30) !important'} />

      <Footer />
    </Box>
  );
}
