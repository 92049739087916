'use client';

import Navbar from '../components/navbar/navbar';
import Footer from '../components/footer/footer';
import Timeline from '../components/timeline/newtimeline';
import { Divider, Box } from '@chakra-ui/react';

export default function Roadmap() {
  return (
    <Box background={'#010810'}>
      <Navbar />
      <Timeline />
      <Divider borderColor={'rgba(23, 27, 30) !important'} />
      <Footer />
    </Box>
  );
}
