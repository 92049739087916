import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import Home from './pages/home';
import Contact from './pages/contact';
import Roadmap from './pages/roadmap';
import Privacy from './pages/legal/privacy';
import Terms from './pages/legal/terms';
import Commercial from './pages/legal/commercial';
import Items from './pages/legal/items';
import Risk from './pages/legal/risk';
import Cookies from './pages/legal/cookies';
import theme from './theme';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="contact" element={<Contact />} />
        <Route path="roadmap" element={<Roadmap />} />
        <Route path="legal/privacy" element={<Privacy />} />
        <Route path="legal/terms" element={<Terms />} />
        <Route path="legal/commercial" element={<Commercial />} />
        <Route path="legal/items" element={<Items />} />
        <Route path="legal/risk" element={<Risk />} />
        <Route path="legal/cookies" element={<Cookies />} />
      </Routes>
    </ChakraProvider>
  );
}

export default App;
