'use client';

import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';

import { Divider, Box } from '@chakra-ui/react';

import '../legal/legal.css';

export default function Items() {
  return (
    <Box background={'#010810'}>
      <Navbar />
      <br />
      <section className="legal-section">
        <div className="legal-wrapper">
          <div className="legal-header">
            <p>Special Conditions for Use and Conversion of Items</p>
            <hr />
          </div>
          <br />
          <div className="text-wrapper">
            <p>
              This Special Conditions for Use and Conversion of Items (Special
              Conditions) has been concluded by and between (i) Unipoly Yazılım
              ve Teknoloji Anonim Şirketi (Unipoly) located in Esentepe Mah.
              Haberler Sk. No: 1 İç kapı no: 1 Şişli/İstanbul and (ii) Players
              who access the Platform and benefit from the services, to
              determine terms and conditions for Players to use and benefit from
              the Items provided in the Platform.
            </p>
            <p>
              This Special Conditions is an integral part of the Terms of Use
              (Agreement). The Agreement shall remain in effect for the
              provisions that are not regulated under this Special Conditions.
            </p>
            <p>
              By utilizing the Items, the Player accepts and declares to have
              read all the terms and conditions included in the Special
              Conditions, to have fully understood its content and to have
              approved all the provisions in the electronic environment
              regardless of whether it benefits from the services provided.
            </p>
            <p>
              Throughout this Agreement Unipoly and the Player shall
              individually be referred to as “Party” and jointly as “Parties”.
              Any capitalized words not defined herein are defined in the
              Agreement and these shall have the meaning assigned next to them
              in the Agreement.
            </p>
            <p>
              The Player may acquire “items” offered by Unipoly (Item/s) solely
              on the Platform. The acquisition of Items is intended solely for
              the Player's personal entertainment and, unless explicitly stated
              otherwise in the Agreement or any other terms disclosed by
              Unipoly, Items hold no tangible value in the real world. Players
              do not attain any legally enforceable property rights associated
              with any Item through transactions on the Platform.
            </p>
            <p>
              The transactions related to Items in the Platform may involve an
              application operating on one or more blockchain networks, possibly
              incorporating the Ethereum Network (ERC20). These networks may
              utilize smart contracts facilitating the interaction with or
              facilitating the conversion of Items within the Platform. All
              transactions regarding Items are managed and confirmed on a
              blockchain. The Player accepts and understands that the blockchain
              public address may be made publicly visible whenever the Player
              engages in a transaction.
            </p>
            <p>
              Items can be converted to Unipoly Coin; which is a utility token
              which is interacting with applications that are developed and
              published by Unipoly, that powers the Unipoly game ecosystem and
              enhances user engagement. Such transactions are final and, unless
              otherwise permitted by Unipoly through its policies or practices,
              non-refundable and non-reversible. The Players in any case,
              regardless of the transactions within the Platform and with the
              Items, to initiate any transaction/trading activity regarding
              Unipoly Coin, shall read and accept the Risk Statement that is
              prepared to inform the visitors of https://unipolycoin.com/ about
              the risks associated with trading activities of Unipoly Coin and
              the trading services provided by Bzetmex Teknoloji Anonim Şirketi,
              CoinW Co., Ltd. and/or any other third-party trading platforms
              that Unipoly might engage with in the future.
            </p>
            <p>
              The Player is expressly informed and acknowledges that either the
              Unipoly Coin or Items are by no means considered as "money" or a
              “real currency”, do not serve as a substitute for money or for
              real currency, do not earn interest, have no equivalent value in
              real currency and cannot be bypassed or circumvented. In addition,
              Unipoly is not obligated to exchange a Player’s Items for anything
              else of value.
            </p>
            <p>
              The Player bears responsibility for all transfer fees and
              conversion deductions incurred in the process of converting Items
              to Unipoly Coin. The Player acknowledges and commits to refrain
              from raising any claims concerning these fees or deductions
              against Unipoly. Furthermore, the Player agrees that Unipoly holds
              no responsibility in relation to such matters. Specific rules
              governing the conversion of each Item will be outlined and
              presented in detail during each transaction.
            </p>
            <p>
              Players are prohibited from utilizing, obtaining, or disseminating
              Items or Unipoly Coin beyond the confines of the Platform, unless
              expressly authorized by Unipoly in accordance with the Agreement
              or Unipoly Coin Risk Statement. Any attempt otherwise constitutes
              a breach, rendering the transaction void and may result in
              immediate suspension or termination of the Player's account and
              any license granted by Unipoly. Unipoly explicitly disavows any
              acknowledgment or responsibility for third-party services
              facilitating the sale, transfer, or purchase of Items or Unipoly
              Coin, constituting a violation of the Agreement or Unipoly Coin
              Risk Statement.
            </p>
            <p>
              Transactions involving Items conducted by the Player are deemed
              final and non-refundable, except as required by applicable law.
              Unipoly retains the authority to modify Items and Unipoly Coin at
              its sole discretion, potentially imposing limits on acquisition
              amounts or altering prices.
            </p>
            <p>
              Players acknowledge and agree in advance that Items and
              transactions related to the Items may be prohibited or restricted
              at any time in accordance with the prevailing legal order.
              Consequently, they may incur losses due to administrative or
              judicial regulations. Unipoly holds no liability in such cases.
              Additionally, if the Applications are suspended or restricted due
              to regulatory circumstances, or if the use of Items or redemption
              is hindered by regulatory/legislative changes, Players cannot
              assert any rights arising from these circumstances.
            </p>
            <p>
              Legal regulations regarding the transactions vary according to the
              country of the visitors. It is the visitors’ responsibility to
              learn and comply with the terms of the local laws or regulations
              to which they are subject.
            </p>
            <p>
              Unipoly shall not be liable for (i) any damages arising from the
              Player’s misuse of the Items, and the Players’ failure to fulfil
              its obligations; (ii) any errors or loss of data and related
              damages arising from the failure to carry out the necessary
              testing processes or back-ups, or from any errors or loss of data
              arising despite such testing or back-ups having been carried out,
              (iii) any loss or disorder of data that may occur as a result of
              incorrect data entry, (iv) any loss or damage or loss of data or
              misinterpretation of data that may arise due to any trojan-virus
              or derivative functions that may reside in third party
              applications used by the visitors and that may have a bad effect
              on the software serviced, making the Platform faulty or
              incomplete, (v) hardware, software, and network-related functions
              and unintentional failures arising, (vi) network design and
              connection errors, (vii) any loss or damage or loss of data or
              misinterpretation of data that may arise due to the inaccuracy or
              incompleteness of the Players’ software, hardware or network or
              third-party applications’ input.
            </p>
            <h3>
              The Players declare and undertake that he has read and accepted
              these Special Conditions before starting to utilize the Items.
            </h3>
          </div>
        </div>
      </section>

      <br />

      <Divider borderColor={'rgba(23, 27, 30) !important'} />

      <Footer />
    </Box>
  );
}
