'use client';

import Navbar from '../components/navbar/navbar';
import Footer from '../components/footer/footer';
import Contactbox from '../components/contact/contact';
import { Divider, Box } from '@chakra-ui/react';
import bg from '../image/bg/bg2.webp';

export default function Contact() {
  return (
    <Box bgImage={bg} bgSize={'cover'}>
      <Navbar />
      <Contactbox />
      <Divider borderColor={'rgba(23, 27, 30) !important'} />
      <Footer />
    </Box>
  );
}
