'use client';

import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';

import { Divider, Box } from '@chakra-ui/react';

import '../legal/legal.css';

export default function Risk() {
  return (
    <Box background={'#010810'}>
      <Navbar />
      <br />
      <section className="legal-section">
        <div className="legal-wrapper">
          <div className="legal-header">
            <p>Special Conditions for Use and Conversion of Items</p>
            <hr />
          </div>
          <br />
          <div className="text-wrapper">
            <p>
              This Risk Statement <b>(Statement)</b> is prepared to inform the
              visitors of
              <a href="https://unipolycoin.com/" style={{ color: '#fffa2b' }}>
                https://unipolycoin.com/
              </a>
              <b> (Website)</b> about the risks associated with trading
              activities of Unipoly Coin which is presented by Unıpoly Yazılım
              ve Teknoloji Anonim Şirketi <b>(Unipoly)</b> and the trading
              services provided by Bzetmex Teknoloji Anonim Şirketi{' '}
              <b>(Bzetmex)</b>, CoinW Co., Ltd.
              <b>(CoinW)</b> and/or any other third-party trading platforms that
              Unipoly might engage with in the future (together hereinafter
              <b>“Trading Platforms”</b>). It is at Unipoly’s sole discretion to
              decide on which platform it will engage with or not.
            </p>
            <p>
              Unipoly Coin is a utility token which is interacting with
              applications that are developed and published by Unipoly, that
              powers the Unipoly game ecosystem and enhances user engagement.
              Unipoly acts solely as a website that only presents Unipoly Coin
              only with the purpose to introduce the Unipoly Coin. By visiting
              the Website and pursuing transactions accordingly, the visitors
              accept and agree that the trading transactions are solely carried
              out by Trading Platforms and that Unipoly does not involve in any
              proceedings. Therefore, Unipoly is not liable or responsible for
              any risk beared by the visitor or any kind of losses, damages,
              fees, costs and expenses (including but not limited to the loss on
              capital/investment). Unipoly is not responsible for any losses
              incurred as a result of cryptocurrency investments made based on
              any information and document provided through the Website; since
              the Website is designed only for the purpose of introducing
              Unipoly Coin but not enabling any trading transaction.
            </p>
            <p>
              As an investor, you have complete control over your investment
              choices and their potential outcomes. While you can make a profit
              by making trading transactions through Trading Platforms, your
              investment may be partially or completely lost. Your investment
              decision will solely determine whether you make a profit or incur
              a loss. Therefore, you should not trade with capital that you
              cannot afford to lose. Unipoly does not make any kind of
              commitment, including a profit commitment to visitors that is
              related to transactions made through the Website. Unipoly is not
              liable if the visitors fail to make the expected profit or loses
              some or all of his investments. The visitors acknowledge and agree
              that they are solely responsible for any investment decisions that
              have been made and any resulting profits or losses. Therefore, the
              visitors waive any right to make claims against Unipoly
              accordingly, including but not limited to waiving any right to
              claim losses or lost profit opportunities.
            </p>
            <p>
              Unipoly does not provide any recommendations or guidance related
              to investment decisions, financial strategies, legal matters, tax
              implications, regulatory compliance, or any other aspects of
              trading activities. The visitors acknowledge and understand that
              the trading of Unipoly Coin will be conducted through Trading
              Platforms. Unipoly may collaborate and/or terminate the
              cooperation with Trading Platforms in the future, and it is at
              Unipoly’s sole discretion to decide on which platform it will
              engage with or not. The visitors shall not have any claims against
              Unipoly in the event that Unipoly engages and/or terminates the
              cooperation with a Trading Platform. Unipoly Coin trading
              transactions conducted through those platforms will solely be
              subject to the terms of use and relevant rules of Trading
              Platforms. The visitors agree to carefully read and review and
              adhere to the terms of use and relevant rules of Trading Platforms
              where they engage in Unipoly Coin trading transactions.
            </p>
            <p>
              The visitors bear sole responsibility for all Unipoly Coin trading
              transactions initiated and executed through the Trading Platforms.
              Unipoly expressly disclaims any and all liability arising from or
              in connection with Unipoly Coin trading transactions, including,
              but not limited to, any kind of losses, damages, fees, costs and
              expenses (including but not limited to the 2 loss on
              capital/investment) incurred due to market fluctuations, trading
              errors, or technical issues encountered on the trading platforms.
              Unipoly shall not be liable for (i) any damages arising from the
              visitors’ misuse of the Website/Trading Platforms/technological
              devices and the visitors’ failure to fulfil its obligations; (ii)
              any errors or loss of data and related damages arising from the
              failure to carry out the necessary testing processes or back-ups,
              or from any errors or loss of data arising despite such testing or
              back-ups having been carried out, (iii) any loss of data or errors
              arising during or as a result of the provision of the
              Website/Trading Platforms/technological devices or the use of the
              Website/Trading Platforms/technological devices, (iv) any loss or
              disorder of data that may occur as a result of incorrect data
              entry, (v) any loss or damage or loss of data or misinterpretation
              of data that may arise due to any trojan-virus or derivative
              functions that may reside in third party applications used by the
              visitors and that may have a bad effect on the software serviced,
              making the Website/Trading Platforms/technological devices faulty
              or incomplete, (iv) hardware, software, and network-related
              functions and unintentional failures arising, (v) network design
              and connection errors, (vi) any loss or damage or loss of data or
              misinterpretation of data that may arise as a result of the
              Website/Trading Platforms/technological devices and the
              Website/Trading Platforms/technological devices due to the
              inaccuracy or incompleteness of the visitors’ software, hardware
              or network or third-party applications’ inputs to the
              Website/Trading Platforms/technological devices. In the event of
              any disputes or issues related to Unipoly Coin trading
              transactions, the visitors agree to seek resolution directly with
              Trading Platforms. Unipoly shall not be involved in or bear any
              responsibility for resolving disputes or issues arising from
              Unipoly Coin trading transactions on third-party platforms.
            </p>
            <p>
              Regarding cryptocurrencies and therefore trading, there is a risk
              of cyber-attacks, system hacking, theft, various security
              breaches, restriction of access to the market, computer viruses,
              communication errors, disruptions, errors, corruptions, delays,
              spyware, technological breakdowns, and termination of access to
              the network. In such a case, the visitors may affect from it.
              Since all of these risks are associated with the use of an
              internet- based system, the visitors declare and undertake that
              Unipoly is not responsible for these risks.
            </p>
            <p>
              The visitors acknowledge and agree in advance that Unipoly Coin
              trading may be prohibited/restricted at any time in the legal
              order to which it is subject and that it may therefore suffer
              losses as a result of an administrative or judicial regulation.
              Unipoly shall have no liability in this regard.
            </p>
            <p>
              Legal regulations regarding Unipoly Coin trading vary according to
              the country of the visitors. It is the visitors’ responsibility to
              learn and comply with the terms of the local laws or regulations
              to which they are subject.
            </p>
            <p>
              The visitors declare and undertake that he has read and accepted
              this Risk Statement before starting Unipoly Coin trading.
            </p>
            <p>
              <span>
                <b>UNIPOLY</b>{' '}
              </span>
              <br />
              Unipoly Coin Trading Risk Statement v1.0.0
            </p>
            <p>
              <span>
                <a href="https://unipolycoin.com/" style={{ color: '#fffa2b' }}>
                  https://unipolycoin.com/
                </a>
                All rights reserved.
              </span>
            </p>
            <p>
              <span>General information: [●] </span>
              <br />
              <span>Technical support: [●]</span>
            </p>
          </div>
        </div>
      </section>

      <br />

      <Divider borderColor={'rgba(23, 27, 30) !important'} />

      <Footer />
    </Box>
  );
}
