'use client';

import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';

import { Divider, Box } from '@chakra-ui/react';

import '../legal/legal.css';

export default function Terms() {
  return (
    <Box background={'#010810'}>
      <Navbar />
      <br />

      <section className="legal-section">
        <div className="legal-wrapper">
          <div className="legal-header">
            <p>Terms Of Use</p>
            <hr />
          </div>
          <br />
          <div className="text-wrapper">
            <p>
              This Terms of Use (Agreement) have been concluded by and between
              (i) Unipoly Yazılım ve Teknoloji Anonim Şirketi (Unipoly) located
              in Esentepe Mah. Haberler Sk. No: 1 İç kapı no: 1 Şişli/İstanbul
              and (ii) Users who access or visit the Platform and benefit from
              the services, to determine terms and conditions for Users to
              benefit from the services provided by Unipoly. By
              accessing/visiting the Platform, the User accepts and declares to
              have read all the terms and conditions included in this Agreement,
              to have fully understood its content and to have approved all the
              provisions in the electronic environment regardless of whether it
              benefits from the services provided. This Agreement has been
              concluded for the purpose of using the Platform by the Users,
              particularly the terms and conditions that should be followed in
              any case while using the Platform, regardless of whether the
              services are available. Throughout this Agreement Unipoly and the
              User shall individually be referred to as “Party” and jointly as
              “Parties”.
            </p>
            <p>
              <span> Definitions </span>Each&nbsp;term referred to&nbsp;in
              this&nbsp;Agreement shall&nbsp;have the meanings assigned next to
              them:
            </p>
            <p>
              <span> Website: </span>The website
              <a className="legal-link" href="“https://unipolygames.com/”">
                “https://unipolygames.com/”
              </a>
              owned by Unipoly or the website operating under another domain
              name by Unipoly,
            </p>
            <p>
              <span> Application(s): </span> Applications released by Unipoly
              that Users install on their devices through virtual stores,
            </p>
            <p>
              <span> Platform: </span>Separately or together Website and
              Application,
            </p>
            <p>
              <span> Visitor(s): </span>Persons who use the Application without
              creating an account and/or who visits the Website,
            </p>
            <p>
              <span> Player(s): </span>Persons who access the Application by
              creating an account,
            </p>
            <p>
              <span> User(s): </span>Separately or together Visitor and Player,
            </p>
            <p>
              <span> Privacy Policy and Personal Data Text: </span> The text on
              the Platform, detailing how and for what purpose the User data is
              processed and the privacy policies of Unipoly.
            </p>
            <p>
              <span></span>
            </p>
            <p>
              <span>Subject and Scope of the Agreement </span>
            </p>
            <ul>
              <li>
                The subject of this Agreement is to determine the terms of use
                for the Platform, the terms of benefitting from the services
                offered by Unipoly on the Platform, and the rights and
                obligations of Parties.
              </li>
              <li>
                This Agreement is exclusively concluded between Parties and
                covers the form and conditions for the services available and
                shall be available in the Platform.
              </li>
              <li>
                The Platform constitutes a single structure with all its
                sub-components and domain names; therefore, all services such as
                http, https, ftp, dns, database, mobile, iOS, Android, e-mail,
                API etc. and documents, photos, videos and similar files
                uploaded by Unipoly on the Platform shall be subject to the
                provisions of this Agreement, regardless of where they are
                physically hosted. Depending on the laws of the jurisdiction
                where Users live, Users may have certain rights that cannot be
                waived through this Agreement and that are in addition to the
                terms of this Agreement and certain provisions of this Agreement
                might be unenforceable as to Users. To the extent that any term
                or condition of this Agreement is unenforceable, the remainder
                of the Agreement shall remain in full force and effect.
              </li>
            </ul>
            <h3>Rights and Obligations of Parties</h3>
            <p>
              The User is required to accept this Agreement to access the
              Platform and benefit from the services offered by Unipoly. In case
              of violation of this Agreement, Unipoly can restrict the User from
              accessing the Platform or terminate the services made available to
              the User.
            </p>
            <p>
              The Player and the Visitor who wants to use the Application
              without creating an account can install and use any Application
              published by Unipoly on its device through virtual stores. The
              User is not required to create an account or register to the
              Application to use the Applications. However, within the scope of
              Article 3.3. of the Agreement, the Player can connect their
              accounts on other platforms such as Facebook, Apple ID, Instagram
              to the Application. Connecting the other platforms such as
              Facebook, Apple ID, Instagram to the Application is entirely at
              the User’s discretion. In such connection to the aforementioned
              accounts, the terms and conditions of the related application
              stores shall be applied in addition to this Agreement and the
              liability shall be borne by the User regarding the usage of such
              application stores.
            </p>
            <p>
              The Player’s progress and development in the Application shall be
              synchronized with its devices. If the Application is deleted from
              the device or installed on another device, the Player shall
              maintain the progress made so far. However, the Visitor who uses
              the Application without creating any account shall not be able to
              maintain its progress within the scope of this provision. The
              Visitor acknowledges that they are aware of this situation and
              Unipoly can under no circumstances whatsoever be held responsible
              for losing the progress or development. The Visitor cannot claim
              anything from Unipoly regarding this. If the Player connects its
              accounts on other platforms such as Facebook, Apple ID, Instagram
              to the Application, provided that such an option is offered in the
              Application, its progress shall be synchronized with its account.
              Therefore the Application shall maintain the progress made by
              re-installing the Application or installing on another device and
              connecting the same account.
            </p>
            <p>
              The Applications can include in-app purchases. In this case, the
              Player shall make the purchase through the Application with the
              help of virtual stores. In this context, regarding the payment
              process, the terms and conditions of the relevant stores
              (including but not limited to) such as Appstore and PlayStore
              shall be applied. For this reason, Unipoly shall not be held
              responsible for technical problems that may occur during the
              payment.
            </p>
            <p>
              The User accepts, declares and undertakes to act in accordance
              with all the terms and conditions stipulated in this Agreement and
              other agreements concluded or shall be concluded with Unipoly as
              well as the rules specified in the Platform and all applicable
              legislation and not to engage in any illegal activity while
              benefiting from the services provided in the Platform and making
              any transaction regarding the services in the Platform. The User
              accepts, declares and undertakes that it has read, understood and
              approved all the terms and conditions regarding the use of the
              Platform by visiting or using the Platform.
            </p>
            <p>
              The responsibility to ensure the security and to duly store the
              access tools, if any, (connected Facebook, Apple ID, Instagram
              account, virtual store account, if any username or password, etc.)
              used by the User in order to benefit from the services offered
              through the Platform and to keep them away from the reach and use
              of third parties shall be borne by the User. Every transaction
              made with the User’s connected account, username and password, if
              any, shall be deemed to be made by the User itself. Unipoly will
              not be responsible for any direct or indirect damages incurred by
              the Users and/or third parties due to all negligence and faults of
              the User in matters such as security, storage, keeping away the
              information of third parties, and use of the User’s means of
              accessing the system and the Platform. Unipoly reserves its right
              to recourse.
            </p>
            <p>
              The Application stores and processes personal data that you have
              provided in order to provide the Application services. It is
              User’s responsibility to keep their phone and access to the app
              secure. Unipoly therefore recommend that Users do not jailbreak or
              root their phone, which is the process of removing software
              restrictions and limitations imposed by the official operating
              system of your device. It could make User’s phone vulnerable to
              malware/viruses/malicious programs, compromise your phone’s
              security features and it could mean that the Application will not
              work properly or at all.
            </p>
            <p>
              Users should be aware that there are certain things that Unipoly
              will not take responsibility for. Certain functions of the
              Applications will require the app to have an active internet
              connection. The connection can be Wi-Fi, or provided by the User’s
              mobile network provider, but Unipoly cannot take responsibility
              for the Application not working at full functionality if Users do
              not have access to Wi-Fi, and do not have any of their data
              allowance left.
            </p>
            <p>
              If Users use the Applications outside of an area with Wi-Fi, the
              terms of the agreement with the related mobile network provider
              will still apply. As a result, the User may be charged by their
              mobile provider for the cost of data for the duration of the
              connection while accessing the Application, or other third-party
              charges. In using the Application, Users accept responsibility for
              any such charges, including roaming data charges if they use the
              app outside of their home territory (i.e. region or country)
              without turning off data roaming. If Users are not the bill payer
              for the device on which the User is using the Application, they
              are deemed that they have received permission from the bill payer
              for using the Application.
            </p>
            <p>
              Unipoly do not take any responsibility for the way the Users use
              the Application i.e. Users need to make sure that their device
              stays charged – if it runs out of battery and User cannot turn it
              on to avail the Application, Unipoly cannot accept responsibility.
            </p>
            <p>
              The User shall carry out the transactions on the Platform in a way
              that will not technically disrupt the Platform. The User accepts,
              declares and undertakes to take all necessary measures, including
              using protective software and licensed products, that are
              necessary for the information, content, materials and all other
              content to be provided to the Platform to be free from any and all
              kinds of malicious programs, viruses, software, unlicensed
              products, trojan horses etc. that may harm the system.
            </p>
            <p>
              Unipoly does not promise that it will always update the
              Application so that it is relevant to Users and/or works with the
              iOS or Android version that the User has installed on their
              device. However, Users shall accept updates to the Application
              when offered to them, Unipoly may wish to stop providing the
              Application and may terminate use of it at any time without giving
              notice of termination to Users. Unless otherwise is agreed, upon
              any termination, (a) the rights and licenses granted to Users in
              these terms will end; (b) Users must stop using the Application,
              and (if needed) delete it from their device.
            </p>
            <p>
              The User accepts, declares and undertakes that all information and
              documents provided within the Platform are accurate, complete,
              up-to-date and lawful. Unipoly assumes no obligation to
              investigate the accuracy of the information and documents
              submitted by the User or uploaded, changed or provided through the
              Platform by the User and shall not undertake and warrant that this
              information and documents are secure, accurate and lawful. Thus,
              the User who provides, changes or transfers the information is
              solely responsible for all the damages that arise or may arise due
              to the incorrect, unlawful or inaccurate information in question
              even if it is not at fault. Unipoly shall reserve its right to
              recourse according to the applicable legislation.
            </p>
            <p>
              The User cannot transfer this Agreement or its rights and
              obligations under this Agreement or rights and obligations
              regarding other agreements concluded with Unipoly to a third party
              or make its membership available to third persons without the
              express written consent of Unipoly.
            </p>
            <p>
              The User agrees, declares and undertakes not to reproduce, copy,
              disseminate, distribute, and transfer the pictures, texts, visual
              or auditory images, files, writings, statistics, notifications,
              contents, databases, catalogues and lists in the Platform in a way
              that violates Unipoly’s rights, personality rights, intellectual
              property rights, property rights or any right regarding goods or
              services that may be subject to intellectual property rights.
            </p>
            <p>
              Unipoly has the right to terminate the User’s access authorization
              to the Platform permanently or terminate all the agreements with
              the User regarding the services provided under the Platform if it
              has been detected that there is a violation of applicable law or
              ethical rules/morality even if there is no violation of agreements
              concluded within the scope of the Platform. In such cases,
              Unipoly’s legal and contractual rights to indemnification are
              reserved. The User shall be liable for all direct or indirect
              damages that arise/may arise from such violations and Unipoly’s
              right to recourse is reserved.
            </p>
            <p>
              Unipoly shall have the right to reject applications to use the
              Platform unilaterally and without putting forth any justification.
              The User agrees declares and undertakes that while visiting and/or
              using the Platform, it shall not take any actions contrary to the
              law and morality or that are likely to harm the benefit from the
              services, including but not limited to those listed below. Unipoly
              does not guarantee that the services offered on the Platform will
              be continuous, high quality, accurate, uninterrupted, error-free,
              and free of viruses or other harmful components or defects. Within
              the scope of this article, Unipoly will not assume any
              responsibility and/or indemnification liability for damages
              arising from the following possible violations which are
              prohibited:
            </p>
            <ul>
              <li>
                To violate this Agreement and provisions of other agreements
                that will be concluded within the scope of the Platform,
              </li>
              <li>
                To act contrary to the provisions of the current legislation and
                the regulations on internet use,
              </li>
              <li>
                To provide incomplete, inaccurate and misleading information
                during registration/membership to the Platform,
              </li>
              <li>
                Failure to provide up-to-date and accurate information about
                oneself, to use information illegally acquired from others,
              </li>
              <li>
                To illegally obtain or attempt to obtain the Users’ information,
              </li>
              <li>
                To create multiple accounts using information and/or documents
                (real or fake) that belong to third parties and/or to use the
                Platform in a way that misleads Unipoly and the Users about
                his/her own identity, by changing/anonymizing the IP address or
                by other means,
              </li>
              <li>
                To unlawfully change, use or attempt to change and use the
                information published by Unipoly,
              </li>
              <li>
                To violate personal and intellectual property rights of Unipoly
                and/or a third party,
              </li>
              <li>
                To upload, share, or publish any content that is unlawful,
                obscene, violates the confidentiality of others, pornographic,
                contains or depicts nudity,
              </li>
              <li>
                To engage in acts that may damage the reputation of Unipoly
                and/or a third party or obstruct Unipoly’s activities,
              </li>
              <li>
                To deliver unlawful information and/or share harmful data such
                as chain mail, malware, viruses,
              </li>
              <li>
                Including but not limited to the following, to share or sign
                information or documents that violate the rights of third
                parties, constitute unfair competition, are unlawful and/or
                immoral,
              </li>
              <li>
                To engage in behaviours and activities that will adversely
                affect/obstruct or manipulate the operation of the Platform, to
                disable security systems and
              </li>
              <li>
                To make the Platform unusable or make an attempt in this manner
                by preparing automatic programs.
              </li>
              <li>
                Excessive and unnecessary consumption of system resources, and
                abuse of systems intentionally and/or unintentionally.
              </li>
              <li>
                In case the User violates Article 3.17. of the Agreement,
                Unipoly has the right to terminate this Agreement unilaterally,
                without any notice and indemnification, and to block the User’s
                access to the Platform and/or account and to close the account
                either for a certain period of time or indefinitely.
                Furthermore, Unipoly reserves the right to resort to civil law
                and criminal law remedies.
              </li>
              <li>
                Unipoly does not assume any responsibility and/or
                indemnification liability for damages if the following
                situations and conditions regarding the Platform occur:
              </li>
              <li>
                The failure of the User to make the necessary updates for the
                Platform in a timely and/or correct manner and consequent
                occurrence of direct and/or indirect damages,
              </li>
              <li>
                User's use of the Platform for an illegal purpose and consequent
                occurrence of direct and/or indirect damage,
              </li>
              <li>
                Direct and/or indirect damages that may arise due to performance
                loss, deficiency, error, outage, internet data package expenses,
                computer virus, line and/or system malfunction during or after
                the use of the Platform,
              </li>
              <li>
                To cause direct and/or indirect damages due to the deactivation
                or incorrect installation of the Platform.
              </li>
              <li>
                The User accepts, declares and undertakes that it is legally
                entitled to any data, information or content it uses, that it is
                legally authorized to use and dispose of the right, and that
                such use does not violate copyrights, Turkish Data Protection
                Law or all relevant laws and does not harm any person. The User
                accepts, declares and undertakes to compensate any damage that
                may have been incurred by both Unipoly and third parties due to
                the fact that it is not legally entitled to any data,
                information or content it uses.
              </li>
              <li>
                Interruptions in the communication between the User and the
                Platform may occur due to technical problems encountered such as
                error, negligence, interruption, deletion, deterioration, delay
                in transmission or communication network failure, and not due to
                any reason caused by Unipoly (infrastructural and/or hardware
                reasons etc.). Unipoly cannot be held responsible for the
                communication problems and problems experienced by the User due
                to such interruptions in access to the Platform.
              </li>
              <li>
                Unipoly may restrict or terminate the access to Applications at
                any time and without giving any further notice in case the
                operating security of the network is at risk, in order to ensure
                the continuity of access to the network, to prevent malfunctions
                that may occur in the network, software or uploaded files, to
                prevent or reduce the adverse effects of possible disruptions
                and in other cases deemed necessary.
              </li>
              <li>
                Unipoly also acts to remove objectionable content. The decision
                to remove objectionable content shall be made at Unipoly’s sole
                discretion. Objectionable content includes, but is not limited
                to: content that is unlawful, harmful, threatening, abusive,
                harassing, tortuous, defamatory or libelous; content that is
                hateful or advocates the hate crimes, hate speech and all types
                of discrimination, harm or violence against a person, group or
                minority; content that may harm minors in any way; content that
                has the aim or effect of stalking or otherwise harassing or
                bullying another; private information regarding any individual
                such as phone numbers, addresses, national ID numbers, Social
                Security numbers or any other information that is invasive of
                another’s privacy; content that is vulgar, offensive,
                discriminative, obscene or pornographic, unsolicited or
                unauthorized advertising, promotional materials, junk mail,
                SPAM, chain letters, pyramid schemes or any other form of
                solicitation; material that contains software viruses or any
                kind of malicious software or any other computer code, files or
                programs designed to interrupt, destroy or limit the
                functionality of any computer or mobile device software or
                hardware or telecommunications equipment.
              </li>
            </ul>
            <p>
              <span style={{ fontWeight: 900 }}>Updates:</span> Unipoly reserves
              the right to add or remove features or functions to the existing
              Applications. Unipoly may require the updating of the Applications
              on User’s mobile device when Unipoly releases a new version of the
              Applications, or when Unipoly makes new features available. This
              update may occur automatically or upon prior notice to the Users
              and may occur all at once or over multiple sessions. The User
              understands that Unipoly may require User’s review and acceptance
              of Unipoly’s then-current Agreement before User will be permitted
              to use any subsequent versions of the Applications. User
              acknowledges and agrees that any obligation Unipoly may have to
              support previous versions of the Applications may be ended upon
              the availability of updates, supplements or subsequent versions of
              the Applications. User acknowledges and agrees that Unipoly has no
              obligation to make available to User any updates, supplements or
              subsequent versions of the Applications.
            </p>
            <p>
              <span style={{ fontWeight: 900 }}>Access: </span>The Users must
              provide at their expense the equipment, internet connections,
              devices and service plans to access and use the Applications. If
              the User accesses the Applications through a mobile network, your
              network or roaming provider’s messaging, data and other rates and
              fees may apply. The User is solely responsible for any costs you
              incur to access the Applications from its device. Downloading,
              installing or using certain the Applications may be prohibited or
              restricted by your network provider and not all the Applications
              may work with your network provider or device. Unipoly makes no
              representation that the Applications can be accessed on all
              devices or wireless service plans. Unipoly makes no representation
              that the Applications are available in all languages or that the
              Applications are appropriate or available for use in any
              particular location.
            </p>
            <p>
              <span style={{ fontWeight: 900 }}>
                Third-Party Services and Content:{' '}
              </span>
              The Applications may integrate, be integrated into, bundled, or be
              provided in connection with third-party services, advertising,
              feeds and/or content. If the User is installing the Applications
              that includes third party services and third-party content, such
              services and content are subject to such third party’s terms of
              services and privacy policies, which might be found on the
              relevant third-party partners’ website. Unipoly has no control
              over such websites and resources, and the User acknowledges and
              agrees that Unipoly shall not be responsible or liable, directly
              or indirectly, for any damage or loss caused or alleged to be
              caused by or in connection with use of reliance on any such
              content, goods or services available on or through any such
              website or resource. Unipoly shall not be a party to or in any way
              be responsible for monitoring any transaction between the User and
              the third-party partners.
            </p>
            <p>
              Any rights and claims for the services cannot be exercised by
              persons under the age of 18 in Turkey and/or age of majority
              according to applicable law (age of majority). Thus, in case the
              User is a real person, the User acknowledges and undertakes that
              it is over the age of majority by approving the Agreement. In case
              the User is under the age of majority, it will be assumed that the
              User has received approval/permission from the parent and/or legal
              guardian, and also the parent and/or legal guardian have read and
              consented to the terms and conditions by approving the Agreement.
              By permitting a minor to use the services provided by the
              Platform, a minor’s parent or legal guardian becomes subject to
              the Agreement and agrees to be responsible for the minor’s
              activities on the Platform. In case the User is a legal person,
              the User acknowledges and undertakes that it is established and
              existing validly, it has all power and authority, including to
              conclude this Agreement, and that its representative who has
              concluded the Agreement has been duly authorized. Unipoly has no
              obligation to investigate the power of representation for the real
              person who has concluded this Agreement on behalf of the relevant
              legal person. If the parent or legal guardian of a child under the
              age of majority believes that he/she is using the Application
              without their prior approval,&nbsp;please contact
              at&nbsp;[support@unipolygames.com].
            </p>
            <p>
              In case the User is a “consumer” under the Consumer Protection Act
              of Turkey (Law No. 6502) and the applicable consumer legislation,
              the User can benefit from the rights and powers vested in
              consumers thereunder to the extent applicable. If the User is
              considered a consumer under Law No. 6502 and to the extent
              applicable, the other relevant legislation they should give prior
              notice to duly inform the Unipoly to use its consumer rights. The
              User accepts and declares that they understand this situation and
              that they shall act in line with the applicable consumer
              legislation, if necessary.
            </p>
            <h3>Grant of License</h3>
            <ul>
              <li>
                Subject to Users’ compliance with the Agreement, Unipoly grants
                them a limited, non-exclusive, revocable, non-sublicensable,
                non-transferable license to access, download and install the
                most current generally available version of the Application on a
                single, authorized mobile device that Users own or control
                solely for their lawful, personal, as an end-user, and
                non-commercial use.
              </li>
              <li>
                For the avoidance of doubt, the Application is protected by
                copyright laws and international copyright treaties, as well as
                other intellectual laws and treaties. Unipoly is the exclusive
                owner of any software, design, source code, target code,
                directory, image or content available on the Application.
                Unipoly reserves all rights not expressly granted to the User
                under this Agreement. Unipoly retains the ownership of the
                copyright in and to the Applications. The User cannot duplicate,
                copy, distribute or process the advertisements, images and
                texts, visual and audio images, files, databases, catalogues and
                lists available on Applications nor rent, lease or lend the
                Applications to anyone and permanently transfer all of their
                rights under this Agreement. Unipoly holds no responsibility for
                the results of using the Applications acquired illegally or
                through an unauthorized distributor.
              </li>
              <li>
                The User shall not rent, sell, lease, sublicense, distribute,
                assign, copy (other than a single copy for your own backup
                purposes) or in any way transfer or grant any rights to the
                Applications or use Applications for the benefit of any third
                party. Unless expressly authorized by Unipoly, the Users are
                prohibited from making the Applications available over a network
                where they could be downloaded or used by multiple users. User
                agrees that they shall not use any robot, spider, other
                automatic or manual device or process to interfere or attempt to
                interfere with the proper working of the Applications, except to
                uninstall or remove the Applications from a mobile device which
                the User owns or controls. User shall not deliver unlawful
                information and/or share harmful data such as chain mail,
                malware, viruses.
              </li>
            </ul>
            <h3>Creating an Account</h3>
            <ul>
              <li>
                To access certain elements and functionality of the Application,
                it is required to create an account. To create an account, a
                username, e-mail address and password must be provided.
                Depending on the resources accessed, it may also be asked to
                provide other information like a verified email address, or
                verified phone number. The method of this verification is solely
                at Unipoly’s discretion.
              </li>
              <li>
                Following the submission of the required information, a
                verification code will be sent to the e-mail address which is
                submitted. The registration will be completed following the
                verification code sent to the User’s email written in the
                specified blank space.
              </li>
              <li>
                Only users who create an account (Players) are eligible to make
                in-app purchases. Users who choose to continue without creating
                an account (Visitors who uses the Application without creating
                any account) shall not have the capability to make in-app
                purchases. Within this context, Unipoly reserves the right to
                allocate one or more features offered within the Platform to
                Players due to technical requirements, corporate functions, or
                other valid reasons. This allocation may restrict Visitors from
                accessing these features. Users acknowledge and agree that
                Unipoly retains the flexibility to modify the access
                restrictions outlined in this article at any time, without any
                obligation to entertain requests from Users in this regard.
              </li>
            </ul>
            <h3>How to Play</h3>
            <ul>
              <li>
                Following creating an account, you can enter the playground
                provided by the Application instantly as a Player and as a
                Visitor without creating an account. In the playground, players
                can play the game by fulfilling the tasks assigned to them,
                teaming up and fighting with the opposing team or fighting
                individually. Tanks, infantry or aeroplanes can be used while
                playing the game.
              </li>
            </ul>
            <h3>Limited Warranty</h3>
            <ul>
              <li>
                Any online facilities, tools, services or information that
                Unipoly makes available through the Platform is provided “as is”
                and on an “as available” basis. We give no warranty that the
                Platform is free of defects and/or faults. To the maximum extent
                permitted by the law, we provide no warranties (express or
                implied) of fitness for a particular purpose, accuracy of
                information, compatibility and satisfactory quality. Whilst
                Unipoly uses reasonable endeavours to ensure that the Platform
                are secure and free of errors, viruses and other malware, we
                give no warranty or guarantee in that regard and all Users take
                responsibility for their own security, that of their personal
                details and their computers. Unipoly accepts no liability for
                any service outage, disruption or non-availability of the
                Platform. Unipoly reserves the right to alter, suspend or
                discontinue any part (or the whole of) the Platform including,
                but not limited to, any products and/or services available.
                These terms shall continue to apply to any modified version of
                the Platform unless it is expressly stated otherwise.
              </li>
            </ul>
            <h3>Limited Liability</h3>
            <ul>
              <li>
                Nothing in these Terms will: (a) limit or exclude User’s
                liability for death or personal injury resulting from User’s
                negligence, as applicable; (b) limit or exclude User’s liability
                for fraud or fraudulent misrepresentation; or (c) limit or
                exclude any of User’s liabilities in any way that is not
                permitted under applicable law. Unipoly shall not be liable to
                User in respect of any losses arising out of events beyond our
                reasonable control. To the maximum extent permitted by law,
                Unipoly accepts no liability for any of the following: any
                business losses, such as loss of profits, income, revenue,
                anticipated savings, business, contracts, goodwill or commercial
                opportunities; loss or corruption of any data, database or
                software; any special, indirect or consequential loss or damage.
              </li>
            </ul>
            <h3>Confidentiality and Personal Data Protection</h3>
            <ul>
              <li>
                Unipoly agrees to fulfill its obligations stipulated in any
                legislation including Turkish Personal Data Protection Law and
                the Privacy Policy and Personal Data Text, regarding the
                confidential information and personal data provided by the User
                through the Platform in order to benefit from the services
                offered on the Platform. The User is obliged to constantly
                follow the Platform and the updates to the Cookie Policy and the
                Privacy Policy and Personal Data Text on the Platform. In this
                context, Users' information can be processed within the scope
                and limits stipulated in the relevant texts, particularly the
                Privacy Policy and Personal Data Text.
              </li>
              <li>
                The User accepts and undertakes that in cases where Unipoly is
                under disclosure obligation to the official authorities in
                accordance with mandatory provisions of the legislation in
                force, the the Privacy Policy and Personal Data Text published
                on the relevant Platform and Turkish Personal Data Protection
                Law, Unipoly when duly requested shall be authorized to disclose
                confidential/private/commercial information belonging to User to
                the official authorities, and therefore no indemnification can
                be claimed from Unipoly under any name.
              </li>
              <li>
                Users agree that Unipoly may collect and use technical data and
                related information, including and not limited to technical
                information about their device, system and application software
                and peripherals, that is gathered periodically to facilitate the
                provision of software updates, product support and other
                services to you (if any) related to the Applications. Unipoly
                may use this information as per the Privacy Policy and Personal
                Data Text.
              </li>
              <li>
                If Users choose to provide app store reviews or reviews via any
                social media channel or other similar communication or messaging
                features or services, such information may be made publicly
                available, including the public-facing username as it appears
                with the review. If Users prefer that Unipoly not use
                promotional purposes, they will be able to elect for us not to
                do so by submitting their request at [support@unipolygames.com].
                For security purposes, please do not include any password,
                social security number, national ID number, payment card or
                other sensitive information via these features. Unipoly has the
                right, but not the obligation, to monitor messages and
                communications between and among Users for security and training
                purposes.
              </li>
              <li>
                If Users download the Applications through virtual stores such
                as Google Play, or AppStore, they accept that posting reviews on
                such virtual stores shall be subjected to their relevant
                policies.
              </li>
            </ul>
            <h3>Intellectual Property Rights</h3>
            <ul>
              <li>
                As a rule, Unipoly is the exclusive owner of any software,
                design, source code, target code, directory, image or content
                available on the Platform. The User agrees, declares and
                undertakes not to violate any intellectual and industrial
                property rights available on the Platform and owned by Unipoly.
                Unipoly is the sole owner of all financial and immaterial rights
                as well as the intellectual property pertaining to the products
                and/or services, projects, documents, images, texts, bulletins,
                slogans, videos, designs, know-how and all kinds of commercial
                information, illustrations, database, any data related to the
                system flow, all kinds of logos, emblems and data, ideas,
                trademarks and commercial views of Unipoly and/or business
                partners, flows, source codes, researches, codes, techniques,
                statistics used in the Platform in relation to the services
                provided under this Agreement and exclusively reserves all
                rights arising from the relevant legislation regarding these
                contents. In this context, the User agrees, declares and
                undertakes not to do any reverse engineering on the Platform,
                alter or remove any copyright, trademark or other proprietary
                rights or engage in any other act to find, obtain or copy the
                source code of the Platform, not to violate the security of any
                computer network, not to hack security passwords and codes, not
                to attempt to deliver SPAM mail or upload malware, and that
                otherwise it shall assume the liability for any damages of
                Unipoly and third parties.
              </li>
            </ul>
            <h3>Indemnification</h3>
            <ul>
              <li>
                Users agree to indemnify and hold harmless Unipoly, its
                affiliates and Unipoly’s and its affiliates officers, directors,
                licensors, partners, shareholders, licensees, contractors,
                agents, attorneys, employees and third-party service providers
                (Indemnitees) from any and all claims, liabilities, costs and
                expenses, including reasonable attorneys’ fees (Claim(s)), that
                actually or allegedly result from your information, use of the
                Platform or your breach of this Agreement.
              </li>
              <li>
                Users agree to be solely responsible for defending any Claim
                against or suffered by any Indemnitee, subject to the relevant
                Indemnitee’s right to participate with counsel of its own
                choosing and for payment of damages or losses resulting from all
                claims against any Indemnitee provided that you will not agree
                to any settlement that imposes any obligation or liability on
                any Indemnitee without Unipoly’s prior express written consent.
              </li>
            </ul>
            <h3>Copyright Infringement</h3>
            <p>
              Respecting intellectual property rights is of utmost importance.
              In case a third-party is a copyright owner or an agent
              representing a copyright owner and believes that any content on
              the Platform infringes upon their copyrights, they have the option
              to submit a notice. To initiate this process, the related
              third-party should contact the designated Copyright Agent by email
              at [●]. In cases where the copyright owner is under the age of
              majority, a notice must be submitted by a parent, guardian, or
              another authorized adult representative under the applicable of
              such jurisdiction. The copyright notice should include the
              following essential information:
            </p>
            <ul>
              <li>
                An electronic or physical signature of the authorized person
                acting on behalf of the copyright owner.
              </li>
              <li>
                A description of the copyrighted work or intellectual property
                right claimed to have been infringed.
              </li>
              <li>
                A clear identification of the material alleged to be infringing,
                including its location.
              </li>
              <li>
                Contact details, including address, telephone number, and email
                address.
              </li>
              <li>
                A statement indicating a good faith belief that the use of the
                material is not authorized by the copyright owner, its agent, or
                applicable law.
              </li>
              <li>
                A statement confirming the accuracy of the information in the
                notice, under penalty of perjury, and asserting the status as
                the copyright owner or authorized representative of the
                copyright or intellectual property owner.
              </li>
            </ul>
            <p>
              Please note that failing to comply with all the requirements
              mentioned in this section may render the copyright notice invalid.
            </p>
            <ul>
              <li>
                In case of a third party’s content has been removed or disabled
                following a copyright notice, and they believe that their
                content does not infringe copyright or that they have the proper
                authorization from the copyright owner, the copyright owner's
                agent, or in accordance with the law to publish and use the
                material in their content, they have the right to submit a
                counter-notice to the Copyright Agent. This counter-notice can
                be sent via email to [support@unipolygames.com].
              </li>
            </ul>
            <p>
              In cases where a user under the age of majority is involved, a
              parent, guardian, or authorized adult representative must submit
              the counter-notice under the applicable of such jurisdiction.
            </p>
            <h3>Amendments</h3>
            <p>
              Unipoly may unilaterally amend any provision of this Agreement at
              any time it deems appropriate and under its own discretion by
              announcing it on the Platform. Unipoly shall make the
              notifications required to be made to the User within the scope of
              this Agreement through noticeable methods, such as sending emails
              to the e-mail addresses that the User has notified to Unipoly and
              making announcements in the relevant sections of the Platform that
              are accessible to the User. The User shall be obliged to follow
              these amendments and updates. Unamended provisions of this
              Agreement shall remain in force as they are and continue to bear
              legal consequences.
            </p>
            <h3>Force Majeure</h3>
            <ul>
              <li>
                Events that occur beyond the reasonable control of parties, that
                are unpredictable to Parties at the time of the conclusion of
                the Agreement and cause Parties to fail to fulfill their
                obligations stipulated in this Agreement including natural
                disasters, war, civil war, mobilization, fire, pandemic
                (epidemic), explosion, riot, terrorism, cyber-attack, long-term
                and far-reaching power outage, decision to block the access to
                the website(s) where advertisement(s) will be published, access
                to IP addresses which these sites and ad server systems are
                connected, and legislative amendments are considered as force
                majeure within the scope of this Agreement.
              </li>
              <li>
                Unipoly cannot be held liable in any way for the delayed,
                incomplete or non-performance of any of the undertakings
                specified in this Agreement due to the events enlisted in this
                article as well as in all cases that may legally be considered
                as force majeure although it is not included in this article.
                Such cases of force majeure shall not be considered as
                incomplete performance, non-performance or default or the User
                shall not demand any indemnification under any name from Unipoly
                for such cases.
              </li>
              <li>
                In the event that the force majeure event lasts longer than 30
                (thirty) days, Unipoly shall reserve the right to unilaterally
                terminate this Agreement without assuming any obligation to pay
                indemnification.
              </li>
            </ul>
            <h3>Applicable Law and Jurisdiction</h3>
            <ul>
              <li>
                Turkish Law will be applicable in the implementation and
                interpretation of this Agreement and management of legal
                relations arising within the provisions of this Agreement.
                Istanbul Courts and Enforcement Offices will be competent in the
                settlement of any dispute that arises/may arise from this
                Agreement.
              </li>
            </ul>
            <h3>Termination of the Agreement</h3>
            <ul>
              <li>
                This Agreement will enter into force when the User accesses the
                Platform or benefits from the services offered; and will end
                when the User is permanently restricted from accessing the
                Platform, the use of the Application is terminated and/or ended
                for any reason. However, Parties are obliged to mutually fulfill
                their rights and obligations until the ending date of the
                Agreement.
              </li>
              <li>
                Each User who accesses the Platform or benefits from the
                services offered shall be bound by the provisions of this
                Agreement for as long as it accesses the Platform and benefits
                from the services offered.
              </li>
              <li>
                In case the User violates this Agreement or the rules and
                agreements regarding the different services offered through the
                Platform, and especially in the cases listed below, Unipoly may
                terminate this Agreement unilaterally, without putting forth any
                justification, without the need to give any prior notice and
                without assuming any liability to pay indemnification:
              </li>
              <li>
                The User behaves in a manner that manipulates the operation of
                the Platform by using any method,
              </li>
              <li>
                The User’s violation of this Agreement and/or other service
                agreements,
              </li>
              <li>
                The User’s actions that violate the rights of third parties,
              </li>
              <li>
                The User’s share of information, content, visuals, texts on the
                Platform or with Unipoly that carry unlawful or immoral element,
                or the User sharing such information, content, visuals, texts
                with immoral and unlawful purposes even if they do not carry
                such elements.
              </li>
              <li>
                Promptly upon termination of this Agreement, the User must cease
                all use of the Application and uninstall, remove or destroy all
                copies of the Application in its possession or control. Having
                said that, termination shall not limit any of Unipoly’s other
                rights or remedies at law.
              </li>
            </ul>
            <h3>Evidential Contract</h3>
            <p>
              All kinds of electronic or physical records and documents held by
              Unipoly in any dispute that may arise from this Agreement and its
              annexes shall constitute valid, binding and conclusive evidence.
              The User accepts, declares and undertakes that this article is an
              evidential contract within the context of the Code of Civil
              Procedure No. 6100.
            </p>
            <h3>Miscellaneous</h3>
            <ul>
              <li>
                The User accepts, declares and undertakes that it is aware of
                the fact that Unipoly not using any of the rights listed in this
                Agreement and annexes on time or being unable to use such rights
                at all shall not mean that these rights have been waived,
                terminated and/or Unipoly has waived any right or default
                situation for the benefit of the User.
              </li>
              <li>
                The Player declares and accepts that the e-mail address she/he
                added while registering to the Platform is their legal
                notification address in accordance with the Notification Law. If
                the change of address is not notified to Unipoly by the Player
                within 3 (three) business days, the notifications made to such
                e-mail address known by Unipoly will be considered legally
                valid. Within the scope of this Agreement, in cases where there
                is no need for a notification within the scope of Article 18/3
                of the Turkish Commercial Code, other notifications and warnings
                regarding the Agreement will be made to the e-mail address that
                the Player entered while registering to the Platform. Any
                notification and warning to be made by the User to Unipoly can
                be made to Unipoly's [support@unipolygames.com] e-mail address.
                In the event that Visitor contacts Unipoly regarding any matter
                through this e-mail address, the e-mail address provided by
                Visitor while contacting Unipoly shall be the legal notification
                address for notifications to be made to the Visitor thereafter.
              </li>
              <li>
                The User accepts that he/she is not granted any titles and
                statuses as a representative, official, agent, commercial agent,
                partner, solution or business partner, dealer of Unipoly and any
                similar title and status under this Agreement, and that he/she
                cannot make such a declaration in all its documents, brochures
                and announcements, on his/her website, among its references
                related to himself/herself or third parties.
              </li>
              <li>
                This Agreement covers all agreements regarding the access to the
                Platform and use of services and supersedes all prior written
                and oral agreements and commitments.
              </li>
              <li>
                This Agreement has entered into force with each provision being
                read and fully understood by the User and confirmed
                electronically.
              </li>
            </ul>
            <h3>Unipoly Terms of Use v1.0.0</h3>
            <h3>
              <a className="legal-link" href="https://unipolygames.com/">
                https://unipolygames.com/{' '}
              </a>
              all rights reserved.
            </h3>
            <h3>
              <span style={{ fontWeight: 900 }}>
                General information:
                <a className="legal-link" href=" [support@unipolygames.com]">
                  [support@unipolygames.com]
                </a>
              </span>
            </h3>
            <h3>
              <span style={{ fontWeight: 900 }}> Technical support: </span>
              <a className="legal-link" href="[info@unipolygames.com]">
                [info@unipolygames.com]
              </a>
            </h3>
            <p />
          </div>
        </div>
      </section>

      <br />

      <Divider borderColor={'rgba(23, 27, 30) !important'} />

      <Footer />
    </Box>
  );
}
