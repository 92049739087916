import React from 'react';
import Marquee from 'react-fast-marquee';
import { Image, Container } from '@chakra-ui/react';
import cmc from '../../image/sponsors/cmc.webp';
import coingecko2 from '../../image/sponsors/coingecko.webp';
import eth2 from '../../image/sponsors/ethereum.webp';
import coinw from '../../image/sponsors/coinw.webp';
import hyperbc from '../../image/sponsors/hyperbc.webp';
import bzetmex from '../../image/sponsors/bzetmex.webp';
import hyperpay from '../../image/sponsors/hyperpay.webp';
import triology from '../../image/sponsors/trilogy.png';
import mexc from '../../image/sponsors/full-mexc-logo.png';
import codeo from '../../image/sponsors/codeo-beyaz.svg';
import { useInView, animated } from '@react-spring/web';
import './sponsors.css';
function Sponsors() {
  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        x: -100,
      },
      to: {
        opacity: 1,
        x: 0,
      },
    }),
    { once: true }
  );

  return (
    <animated.div ref={ref} style={springs}>
      <Container maxW={'82rem'} p={'40px 0px 40px 0px'} mt={'2%'}>
        <Marquee
          gradient={true}
          gradientColor="rgb(1,8,16)"
          gradientWidth={100}
        >
          <a href={'https://coinmarketcap.com/'}>
            <Image className="sponsor-image" loading="lazy" src={cmc} />
          </a>
          <a href={'https://www.coingecko.com/tr'}>
            <Image className="sponsor-image" loading="lazy" src={coingecko2} />
          </a>
          <a href={'https://ethereum.org/en/'}>
            <Image className="sponsor-image" loading="lazy" src={eth2} />
          </a>
          <a href={'https://www.coinw.com/'}>
            <Image className="sponsor-image" loading="lazy" src={coinw} />
          </a>
          <a href={'https://bzetmex.com/'}>
            <Image className="sponsor-image" loading="lazy" src={bzetmex} />
          </a>
          <a href={'https://www.hyperbc.com/'}>
            <Image className="sponsor-image" loading="lazy" src={hyperbc} />
          </a>
          <a href={'https://www.hyperpay.tech/'}>
            <Image className="sponsor-image" loading="lazy" src={hyperpay} />
          </a>
          <a href={'https://www.trilogy.news/'}>
            <Image className="sponsor-image" loading="lazy" src={triology} />
          </a>
          <a
            href={'https://www.mexc.com/tr-TR/exchange/UNP_USDT?_from=market/'}
          >
            <Image
              className="sponsor-image"
              loading="lazy"
              style={{ width: '230px', height: 'auto' }}
              src={mexc}
            />
          </a>
          <a href={'https://codeo.com.tr/'}>
            <Image className="sponsor-image" loading="lazy" src={codeo} />
          </a>
        </Marquee>
      </Container>
    </animated.div>
  );
}

export default Sponsors;
